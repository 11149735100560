module.exports = {
    "dashboard": "首頁",
    "myAccounts": "我的帳戶",
    "internationalTransfer": "國際轉帳",
    "fundingType": "資金類型",
    "deposit": "存入",
    "withdraw": "提出",
    "invoice": "發票",
    "requestMoney": "請款",
    "recipients": "收件人",
    "developers": "開發者",
    "myProfile": "我的檔案",
    "logout": "登出",
    "qucikBooks": "QuickBooks",
    "profileDetails": "個資",
    "password": "密碼",
    "users": "使用者",
    "qbDownloads": "QB下載",
    "transfer": "轉換",
    "allTransactions": "所有交易",
    "recurringTrans": "經常性交易",
    "firstName": "名字",
    "lastName": "姓氏",
    "emailAddress": "電子邮箱",
    "userRole": "用戶角色",
    "settings": "設定",
    "oldPass": "舊密碼",
    "newPass": "新密碼",
    "confirmPass": "密碼確認",
    "passwordRequirements": "輸入密碼",
    "reqOne": "至少8個字母",
    "reqTwo": "至少一個小寫字母",
    "reqThree": "至少有一個大寫字母",
    "reqFour": "至少一個數字",
    "reqFive": "至少一個特殊符號",
    "reqSix": "至少一個特殊符號",
    "reqSeven": "沒有間隔",
    "saveChanges": "儲存變更",
    "onlyIntAccounts": "僅限國際帳戶",
    "availableBalance": "可用餘額",
    "currentBalance": "目前餘額",
    "lastUpdatedDate": "最後更新日期",
    "channel": "管道",
    "webhook": "訂閱",
    "recipientName": "收件人姓名",
    "aCHAccountNumber": "ACH 帳號",
    "wireAccountNumber": "匯款帳號",
    "aPIKey": "API 密碼",
    "clientName": "客戶名稱",
    "createdBy": "創建者",
    "transactionID": "交易代號",
    "sourceType": "來源類型",
    "accountReference": "帳號參考",
    "routingReference": "路由參考",
    "expiryDate": "到期日期",
    "recipientEmail": "收件人電子郵件",
    "creationDate": "創建日期",
    "startDate": "開始日期",
    "reference": "參考",
    "transactionType": "交易類型",
    "name": "名字",
    "phone": "電話",
    "role": "角色",
    "complete": "完成",
    "failure": "失敗",
    "returns": "退回",
    "count": "計數",
    "accounts": "帳號",
    "noAccounts": "無帳號",
    "transactionsType": "交易類型",
    "mostRecentTransactions": "最近的交易",
    "export": "出口",
    "transactionHistory": "交易歷史記錄",
    "transactionReference": "交易參考",
    "parentReference": "參考原因",
    "paymentReference": "付款參考",
    "amount": "金額",
    "invoiceNumber": "發票號碼",
    "feeAmount": "費用金額",
    "taxAmount": "稅金金額",
    "exciseTax": "消費稅",
    "salesTax": "銷售稅",
    "registrationFee": "註冊費用",
    "serviceFee": "服務費",
    "conversionRate": "兌換率",
    "oFACMatch": "OFAC 配對",
    "type": "類型",
    "recurringReference": "經常性參考",
    "status": "狀態",
    "created": "建立完成",
    "account": "帳號",
    "receiver": "接收者",
    "description": "備註​",
    "paymentType": "付款類型",
    "from": "From ",
    "sendVia": "發送方式",
    "recipientEmailAddress": "收件人電子郵件郵箱",
    "recipientPhoneNumber": "收件人電話號碼",
    "recipientAccountNumber": "收件人帳號",
    "confirmAccountNumber": "確認帳號",
    "to": "To",
    "currency": "幣別",
    "direction": "方向",
    "paymentDate": "付款日期",
    "service": "服務",
    "frequency": "頻率",
    "endDate": "結束日期",
    "numberofTransfers": "轉帳次數",
    "internalDescription": "內部描述",
    "accountNumber": "帳號",
    "pc_accountNumber": "帳號​",
    "fileName": "檔案名稱",
    "recipient_email": "收件人電子郵件",
    "createdDate": "建立日期",
    "dueDate": "到期日期",
    "memo": "備註",
    "action": "執行",
    "email": "電子郵箱",
    "phoneNumber": "電話號碼",
    "bankName": "銀行帳號",
    "recipientType": "收件人類型",
    "fromAccountNumber": "開始帳號",
    "nameonCard": "卡片上的名字",
    "zipCode": "郵政編號",
    "accountName": "帳戶名稱",
    "routingNumber": "路由號碼",
    "accountType": "帳號類型",
    "address": "地址",
    "city": "城市",
    "country": "國家",
    "state": "狀態",
    "verificationType": "驗證類型",
    "debitAmount": "借方金額",
    "firstCreditAmount": "首次貸記金額",
    "secondCreditAmount": "第二次貸記金額",
    "nickName": "暱稱",
    "beneficiaryBankCode": "收款銀行代碼",
    "addressLine1": "地址第一行",
    "addressLine2": "地址第二行",
    "paymentMethod": "付款方式",
    "referenceID": "參考編號",
    "aCHDetails": "ACH 明細",
    "wireDetails": "轉帳明細",
    "internationalTransferDetails": "國際轉帳明細",
    "selectDate": "選擇日期",
    "sender": "寄送人",
    "remarks": "備註",
    "buyorSell": "買入或賣出",
    "toAccountNumber": "到帳號",
    "paymentInstrument": "付款指示",
    "fromaccount": "從帳號",
    "toCounterparty": "到交易對方",
    "purposeofPayment": "付款目的",
    "fXRate": "匯率",
    "fXAmount": "外匯金額",
    "fXCurrency": "外匯貨幣",
    "toName": "命名",
    "toAccount": "請選轉入銀行帳號​",
    "nextTransactionDate": "下一個交易日期",
    "aPICode": "API 代碼",
    "publicKey": "公用密碼",
    "privateKey": "個人密碼",
    "optional": "選項",
    "additionalInformation": "附加訊息",
    "createPayment": "新增付款",
    "downloadPDF": "下載PDF檔案",
    "resend": "重新傳送",
    "approve": "核准",
    "decline": "拒絕",
    "addCard": "新增卡片",
    "addBankAccount": "新增銀行帳號",
    "addNewRecipient": "新增收件人",
    "synchronizeQuickBooks": "同步QuickBooks ",
    "create": "創建",
    "newUser": "新用戶",
    "generate": "產生",
    "wITHDRAW": "提取",
    "confirm": "確認",
    "cancel": "取消",
    "close": "關閉",
    "sendResetInstructions": "重新發送說明",
    "logIn": "登入",
    "resetPassword": "重設密碼",
    "setPassword": "設定密碼",
    "signUp": "報名",
    "forgotPassword": "忘記密碼",
    "backToLogin": "回到登入畫面",
    "enterOtp": "輸入驗證碼",
    "resendCode": "發送新代碼",
    "selectPayment": "選擇付款方式",
    "scheduleRecurringTransaction": "安排經常性交易",
    "forgotPassText": "輸入您的帳戶註冊的電子郵箱,我們將發送一封重設密碼的說明到您的電子郵箱",
    "unableToCP": "無法創建付款",
    "plsSelectPaymentTyp": "請選擇付款方式",
    "plsSelectFromAcc": "請從帳戶中選擇",
    "selectPaymentPayment": "請選擇付款方式",
    "enterEmail": "請輸入電子邮箱",
    "emailValidation": "電子郵件地址不能超過320個字母",
    "enterPhone": "請輸入一個有效的電話號碼",
    "enterAccNum": "請輸入帳號",
    "AccNum": "請輸入帳號",
    "selectRecipientName": "請選擇收件人姓名",
    "enterAmounttoTransfer": "請輸入要轉帳的金額",
    "enterCurrency": "請輸入要轉帳的幣別",
    "selectDirection": "請選擇方向",
    "selectPaymentDate": "請選擇付款日期",
    "selectPaymentdate": "請選擇付款日期",
    "selectServiceType": "請選擇服務類型",
    "selectFrequency": "請選擇交易次數",
    "enterAmount": "請輸入金額",
    "selectTxnDate": "請輸入交易日期",
    "selectRecipientType": "請選擇收件人類型",
    "enterEmailAddress": "請輸入電子邮箱帳號",
    "validEmail": "請輸入一個有效的電子邮箱",
    "EmailValidation": "電子邮箱不能超過320個字",
    "selectFrmAcc": "請從帳戶中選擇",
    "invoiceAlpha": "發票號碼應為字母數字",
    "enterInvoiceNum": "請輸入發票號碼",
    "invoiceValidation": "發票號碼不能超過150個字母",
    "amountToTransfer": "請輸入要轉帳的金額",
    "valueForMemo": "請在備忘錄中輸入價格",
    "memoValidation": "備註不能超過255個字母",
    "selectPaymentType": "請選擇付款方式",
    "enterName": "請輸入名字",
    "ValidName": "請輸入有效名稱",
    "nameValidation": "名字不能少於三個字母",
    "Name": "請輸入名字",
    "validname": "請輸入有效名稱",
    "nameValid": "名字不能少於三個字母",
    "enterZipCode": "請輸入郵政編號",
    "validZipCode": "請輸入有效的郵政編號",
    "validzipcode": "郵政編號不能少於5位數",
    "validAccName": "請輸入帳戶名稱",
    "enterValidAccName": "請輸入有效的帳戶名稱",
    "maxAccName": "帳戶名稱不能超過20位數字",
    "minAccName": "帳戶名稱不能超過4位數字",
    "accNum": "請輸入帳號",
    "validAccNum": "請輸入有效的帳號",
    "maxAccNum": "帳號不能超過20位數",
    "japanMaxAccNum": "帐号不能超过 35 位数字。",
    "japanMaxBankName": "银行名称不能超过 35 位数字。",
    "japanMinSwiftCode": "Swift 代码应至少为 8 个字符",
    "japanMinAddress": "地址不能少于 2 个字符",
    "japanMaxCity": "城市不能超过 35 个字符。",
    "minAccNum": "帳號不能少於4位",
    "routingNum": "請輸入Routing 號碼",
    "validRoutingNum": "請輸入有效的Routing 號碼",
    "minRoutingNum": "Routing 號碼不能少於9位數字",
    "ValidRoutingNum": "請輸入有效的匯款路線號碼號碼",
    "Acctype": "請選擇帳號類型",
    "validAddress": "請選擇有效的地址",
    "maxAddress": "地址不能超過400個字母",
    "minAddress": "地址不能少於5個字母",
    "City": "請輸入您的城市名稱",
    "maxCity": "城市名稱不能超過60個字母",
    "countryName": "請選擇城市名稱",
    "stateName": "請選擇州的名稱",
    "validzipCode": "請輸入有效的郵遞區號",
    "selectVerificationType": "請選擇驗證類型",
    "debitAmnt": "請輸入借記金額",
    "creditAmnt": "請輸入貸記金額",
    "CreditAmount": "請輸入貸記金額",
    "AccName": "請輸入帳戶名稱",
    "recipienttype": "請選擇收件人類型",
    "accNumber": "請輸入帳號",
    "validAccnum": "請輸入有效的帳號",
    "maxAccNumber": "帳號不能超過20位數",
    "minAccNumber": "帳號不能少於4位數",
    "beneficiaryBankcode": "請輸入收款銀行代號",
    "minRoutingNumber": "Routing 號碼不能少於9位數字",
    "acountNumber": "請輸入帳號號碼",
    "maxAccnum": "帳號不能超過20位數",
    "minAccnum": "帳號不能少於4位數",
    "routingnum": "請輸入Routing 號碼",
    "maxRoutingnum": "Routing 號碼不能少於9位數字",
    "acctype": "請選擇帳號類型",
    "accountNum": "請輸入帳號",
    "validaccountNum": "請輸入有效的帳號",
    "maxAccountNum": "帳號不能超過20位數",
    "minAccountNum": "帳號不能低於4位數",
    "enterRoutingNum": "請輸入Routing 號碼",
    "minRouting": "匯款路線號碼不能少於9位數字",
    "enterAddressLine1": "請輸入地址第一行",
    "maxAddrss": "地址不能超過100個字母",
    "minAddrss": "地址不能超過5個字母",
    "enterCity": "請輸入城市",
    "selectCountryName": "請選國家別",
    "selectStateName": "請選擇州別",
    "validZipcode": "請輸入有效的郵政編號",
    "valdAccnum": "請輸入有效的帳號",
    "countryname": "請輸入國家別",
    "statename": "請選擇有效的州別",
    "selectpaymentMethod": "請選擇付款方式",
    "validPhone": "請輸入有效的電話號碼",
    "inpFname": "請輸入您的名字",
    "inpLname": "請輸入您的姓氏",
    "inpEmail": "請輸入您的電子邮箱",
    "valiemail": "請輸入有效的電子邮箱",
    "validFname": "請輸入有效的名字",
    "maxFname": "名字不能超過50個位數",
    "minFname": "最少要輸入3個字母",
    "validLname": "請輸入有效的姓氏",
    "maxLname": "姓氏不能超過50個字母",
    "minLname": "最少要輸入3個字母",
    "phoneNum": "請輸入電話號碼",
    "validPhoneNum": "請輸入有效的電話號碼",
    "maxPhone": "電話號碼不能超過10個數字",
    "minPhone": "電話號碼必須是10個數字",
    "fromAcc": "請從帳戶中選擇",
    "selectstartDate": "請選擇開始日期",
    "newPsw": "儲存新密碼",
    "unablePsw": "無法更新密碼",
    "inpOldPsw": "請輸入舊密碼",
    "inpNewPsw": "請輸入新密碼",
    "maxPsw": "密碼必須要超過8位數",
    "newPassword": "請確認您的新密碼",
    "result": "結果",
    "buyOrsell": "請選擇買入或售出",
    "frmAccNum": "請從帳號中選擇",
    "selectaccNum": "請選擇帳號",
    "paymentInst": "請選擇付款指示",
    "instType": "請選擇工具類型",
    "insufficientFunds": "餘額不足",
    "unablePayment": "無法建立付款",
    "selectfundingType": "請選擇資金類型",
    "amntTrans": "請輸入要轉帳的金額",
    "counterParty": "請選擇交易方",
    "purposePayment": "請選擇付款目的",
    "toAcc": "請選擇帳號",
    "validUrl": "請選擇有效的URL",
    "all": "全部",
    "loading": "加載中…",
    "transactions": "交易",
    "noAccountData": "沒有帳戶數據",
    "creditTransfer": "學分轉移",
    "noTransactionData": "無交易數據",
    "productCredit": "產品信用",
    "refresh": "刷新",
    "UWallets": "錢包",
    "UWalletNumber": "U錢包號碼",
    "CreditID": "信用編號",
    "InstrumentType": "儀器類型",
    "SelectCurrency": "選擇貨幣",
    "select": "選擇",
    "CardType": "卡的種類",
    "pleaseselecttheCardtype": "請選擇卡片類型",
    "CardNumber": "卡號",
    "ExpirationDate": "截止日期",
    "CVV": "CVV",
    "validCardNumber": "請輸入有效卡號",
    "failedAddCard": "添加卡失敗",
    "cardAddedSuccessfully": "添加卡成功",
    "validExpiration": "請輸入有效的到期日期",
    "validCVV": "請輸入有效的CVV碼",
    "GoBack": "上一頁​",
    "Confirmation": "確認",
    "NoPaymentInstrumentData": "未找到付款方式",
    "deactivatePayment": "您要停用所選的付款方式嗎？",
    "accountVerified": "成功 ！！帳戶詳細信息已成功驗證！",
    "accountNotVerified": "對不起 ！！無法驗證帳戶詳細信息。",
    "validAmount": "請輸入有效金額",
    "noConnectorFound": "沒有可用的連接器",
    "noCountryFound": "未找到國家列表",
    "wentWrongText": "出了些問題！",
    "noStateFound": "未找到州列表",
    "noteText": "注意：使用測試存款流程進行銀行賬戶驗證最多需要 5 個工作日",
    "accountalreadyExists": "帳號已存在",
    "FeatureType": "獎金匯出​",
    "validFeatureType": "請選擇特徵類型。",
    "NoPaymentChannel": "未找到支付渠道",
    "bankAddedsuccess": "銀行賬戶添加成功",
    "bankAddedFailed": "添加銀行賬戶失敗",
    "limitBreach": "突破限制",
    "withdrawMethodSelect": "請選轉出方式​",
    "totalAmount": "總金額",
    "decimalpointErr": "小數點前最多接受 7 位數字。",
    "termsandCondition": "應接受條款和條件。",
    "agreeText": "我同意",
    "termsText": "條款和條件。",
    "minAmountTextAch": "每筆轉出金額最低為",
    "withdrawFeeText1": "每筆轉帳手續費為",
    "withdrawFeeText2": " ",
    "maxAmountWithdrawTxt1": "單筆轉帳金額不得超過",
    "maxAmountWithdrawTxt2": " ",
    "withdrawSuccess": "提現成功！",
    "createAnotherPay": "創建另一個付款",
    "selectCurrency": "請選擇貨幣",
    "lowPayquickerBalance": "您的 payquicker 餘額太低，無法付款",
    "SelectPaymentMethod": "選擇付款方式",
    "availablePayBalance": "可用的 Payquicker 餘額",
    "fundsLoadedSuccess": "資金加載成功！",
    "invalidAccDetails": "帳戶詳細信息無效",
    "insufficientAccBalance": "賬戶餘額不足",
    "invalidRecuDate": "選擇的重複日期無效",
    "invalidTONum": "帳號無效",
    "invalidTrans": "無效交易",
    "counterPartyNotFound": "未找到交易對手",
    "ofacFailed": "OFAC 檢查失敗",
    "interSORFail": "不允許內部 SOR 交易",
    "invalidRoutingNum": "路由號碼無效",
    "requestedServiceNotAvail": "當前請求的服務不可用",
    "limitExceedGlobal": "超出全局限制",
    "clientLimitExceed": "超過客戶交易限額",
    "dailyLimitExceed": "超出每日交易限額",
    "invalidRecdetails": "收件人詳細信息無效",
    "unabletoQuote": "無法獲取報價",
    "enterRecipntPhone": "輸入收件人電話號碼",
    "enterAccNumber": "輸入收件人帳號",
    "accountNotMatch": "帳號不匹配",
    "recipientAccount": "收件人帳戶",
    "convertedAmount": "轉換金額",
    "paymentSuccess": "付款創建成功！",
    "orderNumber": "訂單號",
    "showing": "顯示",
    "of": "的",
    "refNumber": "參考編號",
    "details": "細節",
    "NoTransactionsFound": "沒有發現交易",
    "conntedTOQB": "已成功連接到 QuickBooks",
    "QuoteConvertedAmount": "總報價轉換金額",
    "QBConnectFailed": "無法連接到 QuickBooks",
    "conenctingToQB": "正在連接到 QuickBooks，請稍候…",
    "synchronizeFailed": "無法同步 QuickBooks",
    "synchronizeSuccess": "成功同步 QuickBooks",
    "syncQBText": "與 QuickBooks Online 保持同步",
    "accountConnectedToQB": "帳戶已連接到 QuickBooks",
    "Password": "密碼",
    "profileChangesSaved": "您的個人資料更改已保存",
    "Timezone": "時區",
    "Locale": "語言環境",
    "passwordUpdateSuccess": "您的密碼更改已成功保存",
    "passwordReqText": "密碼必須符合指定要求",
    "passwordNotMatch": "您輸入的兩次密碼不匹配",
    "newUserSuccess": "新用戶創建成功",
    "addNewUser": "添加新用戶",
    "ssoReq": "需要單點登錄",
    "logoutTxt": "成功登出",
    "updatePaymentSuccess": "成功！成功更新付款工具",
    "updatePaymentFailed": "無法更新付款方式",
    "noDataFound": "沒有找到數據",
    "submit": "提交",
    "completed": "完成",
    "failed": "失敗",
    "pending": "待辦",
    "sent": "已發送",
    "SessionExpired": "會話過期",
    "Uwallet": "Uwallet 獎金帳戶",
    "UWalletfunding": "Uwallet 加值帳戶",
    "debit": "借方",
    "credit": "信用/信貸",
    "CARD": "信用卡",
    "BANK": "銀行",
    "active": "活躍",
    "ccintl": "國際信用卡",
    "amex_dis": "美國運通/探索卡",
    "amex_card_text": "添加美國運通卡",
    "nodata": "沒有數據",
    "note": "注意事項",
    "load_fund_via_card": "通過卡加載資金",
    "load_fund_via_ach": "通過 ACH 加載資金",
    "load_fund_via_connector": "通過連接器加載資金",
    "p2m_via_connector": "P2M 通過連接器",
    "creditAdjustment": "信貸調整",
    "debitAdjustment": "借方調整",
    "startDateText": "開始日期",
    "endDateText": "結束日期",
    "payIn": "支付",
    "payOut": "獎金匯出​",
    "checking": "檢查",
    "saving": "保存",
    "verify": "核實",
    "reset": "重置",
    "today": "今天",
    "a2a_reversal": "A2A逆轉",
    "credit_point": "信用積分",
    "commission_payout_via_ach": "通過ACH支付佣金",
    "commission_payout_via_uWallet": "通過 UWallet 支付佣金",
    "commission_payout_via_payquicker": "通過Payquicker支付佣金",
    "withdraw_via_ach": "通過ACH取款",
    "withdraw_via_payquicker": "通過Payquicker提款",
    "credit_reversal": "信用逆轉",
    "load_fund_via_card_reversal": "通過逆轉卡加載資金",
    "uwallet_funding_reversal": "U Wallet 資金逆轉",
    "processing": "加工",
    "declined": "謝絕",
    "onHold": "等候接聽",
    "scheduled": "已預定",
    "cancelled": "取消",
    "beneficiaryName": "銀行戶名​",
    "b_b_code": "銀行代碼和分行代碼(共七碼)​",
    "validBankCode": "請輸入有效代碼",
    "minBankCode": "代碼不能少於 7 位。",
    "load_fund_wallet": "載入資金錢包",
    "ach_transfer": "ACH轉賬",
    "mater_visa": "萬事達卡/Visa卡",
    "go_to_transaction": "前往交易",
    "p2m_reversal": "P2M撤銷",
    "deposit_account_list": "存款賬戶列表",
    "load_fund_via_ach_reversal": "通過 ACH 轉存資金",
    "load_fund_via_connector_reversal": "通過連結轉存資金",
    "KYC_Failed": "KYC驗證失敗",
    "updateKYCText": "您要更新 KYC 詳細信息嗎？",
    "userType": "用戶類型",
    "usertypeErr": "請選擇用戶類型。",
    "UNIONPAY": "銀聯卡",
    "recidencyNumberTxt": "居住號碼",
    "validResNum": "需要居住號碼",
    "validresidencyNum": "請輸入有效的居住號碼",
    "flexText": "PayQuicker Flex",
    "flexTextVietnam": "Thanh toán linh hoạt",
    "withdrawalConfig": "取款配置",
    "withdrawalConfiguration": "取款配置",
    "addConfig": "添加配置",
    "addFreequencyConfig": "添加/編輯頻率配置",
    "ReceivingAccount": "收款賬戶",
    "deactivateConfig": "您要停用選定的配置嗎？",
    "updateConfigSuccess": "成功！ 更新配置成功",
    "updateConfigFailed": "更新配置失敗",
    "add_card_text": "添加信用卡",
    "UWalletTransfer": "U錢包轉賬",
    "daily": '日常的',
    "weekly": "每週",
    "monthly": "每月",
    "biweekly": "雙周刊",
    "immediate": "即時",
    "percentage": "百分比",
    "percentError": "請輸入有效百分比",
    "toDistributor": "致經銷商",
    "distributorId": "請輸入經銷商編號",
    "invalidDistributor": "請輸入有效的經銷商 ID",
    "distributorNotFound": "找不到經銷商",
    "distributorKYCError": "收件人分銷商未驗證",
    "decimalNotPermit": "不允許有小數位",
    "paybyCard": "刷卡支付",
    "validIDNum": "請輸入有效號碼",
    "swiftCode": "斯威夫特代碼",
    "validBankName": "請輸入有效的銀行名稱",
    "JAPANPAYOUT": "銀行",
    "idNumber": "身份證號",
    "commissionHistory": "佣金歷史",
    "CITCON WITHDRAWAL": "銀行提款",
    "validCityName": "請輸入有效的城市名稱",
    "pendingApproval": "待批准",
    "purposeCode": "付款目的",
    "plsSeletpurposeCode": "請選擇付款用途",
    "QualifyingCredit": "合格信用",
    "tooltipAccountName": "请输入您的全名，包括名字和姓氏（如果是个人），例如 John Smith 或您的法定公司全名（如果是公司），例如 ABC Limited",
    "tooltipAccountNumber": "输入银行帐号",
    "tooltipBankCode": "输入银行代码",
    "tooltipBranchCode": "输入分行代码",
    "tooltipSwiftBic": "请输入您银行的 SWIFT BIC（银行识别码），长度可以是 8 或 11 个字符。 如果 SWIFT BIC 只有 8 个字符，请勿通过添加 XXX 填充至 11 个字符",
    "tooltipAddress": "请输入您的住宅（如果是个人）或注册的（如果是公司）门牌号/名称和街道名称，例如 1 Main Street",
    "tooltipCity": "请输入您的居住地址（如果是个人）或注册地址（如果是公司）的城市或城镇，例如伦敦",
    "tooltipZipcode": "请输入您的居住地址（如果是个人）或注册地址（如果是公司）的邮政编码",
    "tooltipCountry": "请选择您的居住地址（如果是个人）或注册地址（如果是公司）所在的国家/地区",
    "tooltipDOB": "请选择您的出生日期（如果仅限个人）",
    "tooltipBankName": "请输入您的银行名称",
    "bankCode": "银行代码",
    "branchCode": "分行代码",
    "dob": "出生日期",
    "CATHAY": "銀行帳戶​",
    "PAYQUICKER": "PAYQUICKER(需持有PAYQUICKER卡)",
    "selct_currency_deposit": "請選擇欲存入幣別​",
    "pc_from_text": "選擇轉出信用卡​",
    "pc_to_text": "選擇存入UW帳號​",
    "pc_amount_text": "輸入欲存入金額​",
    "CNY PAYOUT": "Bank (WP)",
    "accountHolderaddress": "帳戶持有人地址",
    "accountHolderName": "帳戶持有人名稱",
    "branchName": "分店名稱",
    "continue": "繼續",
    "validBranchName": "請輸入有效的分行名稱",
    "SOLU": "銀行提款",
    "cardUpdatedSuccessfully": "卡更新成功",
    "reason": "原因",
    "failedUpdateCard": "卡更新失败",
    "requiredError": "必填欄位",
    "kycNotVerified": "KYC 未驗證。",
    "VND PAYOUT": "提取 WP",
    "phoneNumberAliWP": "86 XXX XXXX XXXX",
    "phoneTooltip": "請提供受益人的電話號碼（範例：86 XXX XXXX XXXX）",
    "emailTooltip": "請輸入受益人的電子郵件地址",
    "ALIPAY PAYOUT WP": "Alipay",

    "enterValidAccNameWithLimit": "Enter Valid Payee name with minimum 2 and maximum 70 characters",
    "enterValidAccNumWithLimit": "Enter Valid account number with minimum 4 and maximum 17 characters",
    "enterValidRoutingNumWithLimit": "Enter Valid routing number",
    "enterValidAddressWithLimit": "Enter Valid Address with minimum 2 and maximum 70 characters",
    "enterValidCityWithLimit": "Enter Valid City with minimum 2 and maximum 35 characters",
    "enterValidZipcodeWithLimit": "Enter Valid Zip code with minimum 2 and maximum 10 characters",

    "enterValidAccNameWithChinese": "Enter valid name in Chinese Characters",
    "enterValidBankNameWithChinese": "Enter valid Bank name in Chinese Characters",
    "enterValidBranchNameWithChinese": "Enter valid Branch name in Chinese Characters",
    "enterValidSoluAccNum": "Enter Valid numeric account number",
    "enterValidSoluAccNumWith20Limit": "Enter Valid numeric account number maximum 20 characters.",
    "enterValidSoluAccNumWith17Limit": "Enter Valid numeric account number maximum 17 characters.",
    "enterValidSoluPhoneNumWithLimit": "Enter Valid numeric phone number maximum 20 characters.",
    "enterValidSoluIDNumWithLimit": "Enter Valid alphanumeric ID number maximum 100 characters.",

    "enterValidAlipayPayeeNameWithLimit": "Enter Valid Payee name with minimum 2 and maximum 35 characters",
    "enterValidAlipayPhoneNumWithLimit": "Enter Valid numeric phone number with minimum = 5 maximum 34 characters.",
    "enterValidAlipayEmailWithLimit": "Enter Valid Email address  with minimum = 6 maximum 100 characters.",

    "enterValidThanhAccNameWithChinese": "Enter Valid  account name with minimum 1 and maximum 140 characters.",
    "enterValidThanhAccNumWithLimit": "Enter Valid  account number  with minimum 1 and maximum 30 characters.",
    "enterValidThanhSwiftCodeWithLimit": "Enter Valid swift code with minimum 8 and maximum 11 characters.",
    "enterValidThanhAddressWithLimit": "Enter Valid  address  with minimum 2 and maximum 35 characters.",

    "enterValidBeneficiaryNameWithLimit": "Enter valid name with maximum 20 characters.",
    "enterValidTWDAccNumWithLimit": "Enter valid account number with maximum 20 characters.",
    "enterValidTWDBranchCodeWithLimit": "Enter valid branch code with maximum 7 characters.",

    "enterValidMYRAccNameWithLimit": "Enter valid name with minimum = 2 and maximum 50 characters.",
    "enterValidMYRAccNumWithLimit": "Enter valid numeric account number with maximum 16 characters.",

    "enterValidTHBAccNameWithLimit": "Enter valid name with maximum 35 characters.",
    "enterValidTHBAccNumWithLimit": "Enter valid numeric account number  with  maximum 12 characters.",
    "enterValidTHBSwiftCodeWithLimit": "Enter valid swift code with maximum 11 characters.",
    "enterValidTHBAddressWithLimit": "Enter valid Address with minimum = 5 and maximum 35 characters.",
    "enterValidTHBCityWithLimit": "Enter valid city with maximum 60 characters.",
    "enterValidTHBZipcodeWithLimit": "Enter valid zip code with minimum = 5 and maximum 10 characters.",

    "enterValidGBPPayeeNameWithLimit": "Enter valid name with Minimum 3 and maximum 50 characters.",
    "enterValidGBPIbanWithLimit": "Enter valid Iban with maximum 22 characters.",
    "enterValidGBPBankNameWithLimit": "Enter valid Bank name with Minimum = 1 and maximum 50 characters.",

    "enterValidEURAccNameWithLimit": "Enter valid name with Minimum 1 and maximum 50 characters.",
    "enterValidEURAccNumWithLimit": "Enter valid numeric account number  with  Minimum 1 and maximum 35 characters.",
    "enterValidEURBankNameWithLimit": "Enter valid Bank name with Minimum = 2 and maximum 10 characters.",

    "enterValidJPYBankNameWithLimit": "Enter valid bank name with  maximum 35 characters.",
    "acceptTermsandconditions": "I followed the guide and set up my Alipay account to receive funds",
    "failedDuplicateCard": "Card addition failed as card already exist.",
    "updatedDate": "Updated Date"
}