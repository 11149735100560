module.exports = {
    "dashboard": "首页",
    "myAccounts": "我的账户",
    "internationalTransfer": "国际转账",
    "fundingType": "资金类型",
    "deposit": "存入",
    "withdraw": "提出",
    "invoice": "发票",
    "requestMoney": "请款",
    "recipients": "收件人",
    "developers": "开发者",
    "myProfile": "我的档案",
    "logout": "注销",
    "qucikBooks": "QuickBooks",
    "profileDetails": "个资",
    "password": "密码",
    "users": "使用者",
    "qbDownloads": "QB下载",
    "transfer": "转换",
    "allTransactions": "所有交易",
    "recurringTrans": "经常性交易",
    "firstName": "名字",
    "lastName": "姓氏",
    "emailAddress": "电子邮箱",
    "userRole": "用户角色",
    "settings": "设定",
    "oldPass": "旧密码",
    "newPass": "新密码",
    "confirmPass": "密码确认",
    "passwordRequirements": "输入密码",
    "reqOne": "至少8个字母",
    "reqTwo": "至少一个小写字母",
    "reqThree": "至少有一个大写字母",
    "reqFour": "至少一个数字",
    "reqFive": "至少一个特殊符号",
    "reqSix": "至少一个特殊符号",
    "reqSeven": "没有间隔",
    "saveChanges": "储存变更",
    "onlyIntAccounts": "仅限国际账户",
    "availableBalance": "可用余额",
    "currentBalance": "目前余额",
    "lastUpdatedDate": "最后更新日期",
    "channel": "管道",
    "webhook": "订阅",
    "recipientName": "收件人姓名",
    "aCHAccountNumber": "ACH 账号",
    "wireAccountNumber": "汇款账号",
    "aPIKey": "API 密码",
    "clientName": "客户名称",
    "createdBy": "创建者",
    "transactionID": "交易代号",
    "sourceType": "来源类型",
    "accountReference": "账号参考",
    "routingReference": "路由参考",
    "expiryDate": "到期日期",
    "recipientEmail": "收件人电子邮箱",
    "creationDate": "创建日期",
    "startDate": "开始日期",
    "reference": "参考",
    "transactionType": "交易类型",
    "name": "名字",
    "phone": "电话",
    "role": "角色",
    "complete": "完成",
    "failure": "失败",
    "returns": "退回",
    "count": "计数",
    "accounts": "账号",
    "noAccounts": "无账号",
    "transactionsType": "交易类型",
    "mostRecentTransactions": "最近的交易",
    "export": "出口",
    "transactionHistory": "交易历史记录",
    "transactionReference": "交易参考",
    "parentReference": "参考原因",
    "paymentReference": "付款参考",
    "amount": "金额",
    "invoiceNumber": "发票号码",
    "feeAmount": "费用金额",
    "taxAmount": "税金金额",
    "exciseTax": "消费税",
    "salesTax": "销售税",
    "registrationFee": "注册费用",
    "serviceFee": "服务费",
    "conversionRate": "兑换率",
    "oFACMatch": "OFAC 配对",
    "type": "类型",
    "recurringReference": "经常性参考",
    "status": "状态",
    "created": "建立完成",
    "account": "账号",
    "receiver": "接收者",
    "description": "摘要",
    "paymentType": "付款类型",
    "from": "選擇轉出信用卡​ ",
    "sendVia": "发送方式",
    "recipientEmailAddress": "收件人电子邮件邮箱",
    "recipientPhoneNumber": "收件人电话号码",
    "recipientAccountNumber": "收件人账号",
    "confirmAccountNumber": "确认账号",
    "to": "To",
    "currency": "币别",
    "direction": "方向",
    "paymentDate": "付款日期",
    "service": "服务",
    "frequency": "频率",
    "endDate": "结束日期",
    "numberofTransfers": "转账次数",
    "internalDescription": "内部描述",
    "accountNumber": "账号",
    "pc_accountNumber": "账号​",
    "fileName": "文件名",
    "recipient_email": "收件人电子邮箱",
    "createdDate": "建立日期",
    "dueDate": "到期日期",
    "memo": "备注",
    "action": "执行",
    "email": "电子邮箱",
    "phoneNumber": "手机号码",
    "bankName": "开户行名称",
    "recipientType": "收件人类型",
    "fromAccountNumber": "开始账号",
    "nameonCard": "卡片上的名字",
    "zipCode": "邮政编号",
    "accountName": "账户名称",
    "routingNumber": "路由号码",
    "accountType": "账号类型",
    "address": "地址",
    "city": "城市",
    "country": "国家",
    "state": "状态",
    "verificationType": "验证类型",
    "debitAmount": "借方金额",
    "firstCreditAmount": "首次贷记金额",
    "secondCreditAmount": "第二次贷记金额",
    "nickName": "昵称",
    "beneficiaryBankCode": "收款银行代码",
    "addressLine1": "地址第一行",
    "addressLine2": "地址第二行",
    "paymentMethod": "付款方式",
    "referenceID": "参考编号",
    "aCHDetails": "ACH 明细",
    "wireDetails": "转账明细",
    "internationalTransferDetails": "国际转账明细",
    "selectDate": "选择日期",
    "sender": "寄送人",
    "remarks": "备注",
    "buyorSell": "买入或卖出",
    "toAccountNumber": "到账号",
    "paymentInstrument": "付款指示",
    "fromaccount": "从账号",
    "toCounterparty": "到交易对方",
    "purposeofPayment": "付款目的",
    "fXRate": "汇率",
    "fXAmount": "外汇金额",
    "fXCurrency": "外汇货币",
    "toName": "命名",
    "toAccount": "记账",
    "nextTransactionDate": "下一个交易日期",
    "aPICode": "API 代码",
    "publicKey": "公用密码",
    "privateKey": "个人密码",
    "optional": "选项",
    "additionalInformation": "附加讯息",
    "createPayment": "新增付款",
    "downloadPDF": "下载PDF档案",
    "resend": "重新传送",
    "approve": "核准",
    "decline": "拒绝",
    "addCard": "新增卡片",
    "addBankAccount": "新增银行账号",
    "addNewRecipient": "新增收件人",
    "synchronizeQuickBooks": "同步QuickBooks ",
    "create": "创建",
    "newUser": "新用户",
    "generate": "产生",
    "wITHDRAW": "提取",
    "confirm": "确认",
    "cancel": "取消",
    "close": "关闭",
    "sendResetInstructions": "重新发送说明",
    "logIn": "登入",
    "resetPassword": "重设密码",
    "setPassword": "设定密码",
    "signUp": "报名",
    "forgotPassword": "忘记密码",
    "backToLogin": "回到登入画面",
    "enterOtp": "输入验证码",
    "resendCode": "发送新代码",
    "selectPayment": "选择付款方式",
    "scheduleRecurringTransaction": "安排经常性交易",
    "forgotPassText": "输入您的帐户註冊的电子邮箱,我们将发送一封重设密码的说明到您的电子邮箱",
    "unableToCP": "无法创建付款",
    "plsSelectPaymentTyp": "请选择付款方式",
    "plsSelectFromAcc": "请从账户中选择",
    "selectPaymentPayment": "请选择付款方式",
    "enterEmail": "请输入电子邮箱",
    "emailValidation": "电子邮件地址不能超过320个字母",
    "enterPhone": "请输入一个有效的电话号码",
    "enterAccNum": "请输入账号",
    "AccNum": "请输入账号",
    "selectRecipientName": "请选择收件人姓名",
    "enterAmounttoTransfer": "请输入要转账的金额",
    "enterCurrency": "请输入要转账的币别",
    "selectDirection": "请选择方向",
    "selectPaymentDate": "请选择付款日期",
    "selectPaymentdate": "请选择付款日期",
    "selectServiceType": "请选择服务类型",
    "selectFrequency": "请选择交易次数",
    "enterAmount": "请输入金额",
    "selectTxnDate": "请输入交易日期",
    "selectRecipientType": "请选择收件人类型",
    "enterEmailAddress": "请输入电子邮箱账号",
    "validEmail": "请输入一个有效的电子邮箱",
    "EmailValidation": "电子邮箱不能超过320个字",
    "selectFrmAcc": "请从账户中选择",
    "invoiceAlpha": "发票号码应为字母数字",
    "enterInvoiceNum": "请输入发票号码",
    "invoiceValidation": "发票号码不能超过150个字母",
    "amountToTransfer": "请输入要转账的金额",
    "valueForMemo": "请在备忘录中输入价格",
    "memoValidation": "备注不能超过255个字母",
    "selectPaymentType": "请选择付款方式",
    "enterName": "请输入名字",
    "ValidName": "请输入有效名称",
    "nameValidation": "名字不能少于三个字母",
    "Name": "请输入名字",
    "validname": "请输入有效名称",
    "nameValid": "名字不能少于三个字母",
    "enterZipCode": "请输入邮政编号",
    "validZipCode": "请输入有效的邮政编号",
    "validzipcode": "邮政编号不能少于5位数",
    "validAccName": "请输入账户名称",
    "enterValidAccName": "请输入有效的账户名称",
    "maxAccName": "帐户名称不能超过20位数字",
    "minAccName": "帐户名称不能超过4位数字",
    "accNum": "请输入账号",
    "validAccNum": "请输入有效的账号",
    "maxAccNum": "账号不能超过20位数",
    "japanMaxAccNum": "帐号不能超过 35 位数字。",
    "japanMaxBankName": "银行名称不能超过 35 位数字。",
    "japanMinSwiftCode": "Swift 代码应至少为 8 个字符",
    "japanMinAddress": "地址不能少于 2 个字符",
    "japanMaxCity": "城市不能超过 35 个字符。",
    "minAccNum": "账号不能少于4位",
    "routingNum": "请输入Routing 号码",
    "validRoutingNum": "请输入有效的Routing 号码",
    "minRoutingNum": "Routing 号码不能少于9位数字",
    "ValidRoutingNum": "请输入有效的汇款路线号码号码",
    "Acctype": "请选择账号类型",
    "validAddress": "请选择有效的地址",
    "maxAddress": "地址不能超过400个字母",
    "minAddress": "地址不能少于5个字母",
    "City": "请输入您的城市名称",
    "maxCity": "城市名称不能超过60个字母",
    "countryName": "请选择城市名称",
    "stateName": "请选择州的名称",
    "validzipCode": "请输入有效的邮政编码",
    "selectVerificationType": "请选择验证类型",
    "debitAmnt": "请输入借记金额",
    "creditAmnt": "请输入贷记金额",
    "CreditAmount": "请输入贷记金额",
    "AccName": "请输入账户名称",
    "recipienttype": "请选择收件人类型",
    "accNumber": "请输入账号",
    "validAccnum": "请输入有效的账号",
    "maxAccNumber": "账号不能超过20位数",
    "minAccNumber": "账号不能少于4位数",
    "beneficiaryBankcode": "请输入收款银行代号",
    "minRoutingNumber": "Routing 号码不能少于9位数字",
    "acountNumber": "请输入账号号码",
    "maxAccnum": "账号不能超过20位数",
    "minAccnum": "账号不能少于4位数",
    "routingnum": "请输入Routing 号码",
    "maxRoutingnum": "Routing 号码不能少于9位数字",
    "acctype": "请选择账号类型",
    "accountNum": "请输入账号",
    "validaccountNum": "请输入有效的账号",
    "maxAccountNum": "账号不能超过20位数",
    "minAccountNum": "账号不能低于4位数",
    "enterRoutingNum": "请输入Routing 号码",
    "minRouting": "汇款路线号码不能少于9位数字",
    "enterAddressLine1": "请输入地址第一行",
    "maxAddrss": "地址不能超过100个字母",
    "minAddrss": "地址不能超过5个字母",
    "enterCity": "请输入城市",
    "selectCountryName": "请选国家别",
    "selectStateName": "请选择州别",
    "validZipcode": "请输入有效的邮政编号",
    "valdAccnum": "请输入有效的账号",
    "countryname": "请输入国家别",
    "statename": "请选择有效的州别",
    "selectpaymentMethod": "请选择付款方式",
    "validPhone": "请输入有效的电话号码",
    "inpFname": "请输入您的名字",
    "inpLname": "请输入您的姓氏",
    "inpEmail": "请输入您的电子邮箱",
    "valiemail": "请输入有效的电子邮箱",
    "validFname": "请输入有效的名字",
    "maxFname": "名字不能超过50个位数",
    "minFname": "最少要输入3个字母",
    "validLname": "请输入有效的姓氏",
    "maxLname": "姓氏不能超过50个字母",
    "minLname": "最少要输入3个字母",
    "phoneNum": "请输入电话号码",
    "validPhoneNum": "请输入有效的电话号码",
    "maxPhone": "电话号码不能超过10个数字",
    "minPhone": "电话号码必须是10个数字",
    "fromAcc": "请从账户中选择",
    "selectstartDate": "请选择开始日期",
    "newPsw": "储存新密码",
    "unablePsw": "无法更新密码",
    "inpOldPsw": "请输入旧密码",
    "inpNewPsw": "请输入新密码",
    "maxPsw": "密码必须要超过8位数",
    "newPassword": "请确认您的新密码",
    "result": "结果",
    "buyOrsell": "请选择买入或售出",
    "frmAccNum": "请从账号中选择",
    "selectaccNum": "请选择账号",
    "paymentInst": "请选择付款指示",
    "instType": "请选择工具类型",
    "insufficientFunds": "余额不足",
    "unablePayment": "无法建立付款",
    "selectfundingType": "请选择资金类型",
    "amntTrans": "请输入要转账的金额",
    "counterParty": "请选择交易方",
    "purposePayment": "请选择付款目的",
    "toAcc": "请选择账号",
    "validUrl": "请选择有效的URL",
    "all": "全部",
    "loading": "加载中…",
    "transactions": "交易",
    "noAccountData": "没有帐户数据",
    "creditTransfer": "学分转移",
    "noTransactionData": "无交易数据",
    "productCredit": "产品信用",
    "refresh": "刷新",
    "UWallets": "钱包",
    "UWalletNumber": "U钱包号码",
    "CreditID": "信用编号",
    "InstrumentType": "仪器类型",
    "SelectCurrency": "选择货币",
    "select": "选择",
    "CardType": "卡的种类",
    "pleaseselecttheCardtype": "请选择卡片类型",
    "CardNumber": "卡号",
    "ExpirationDate": "截止日期",
    "CVV": "CVV",
    "validCardNumber": "请输入有效卡号",
    "failedAddCard": "添加卡失败",
    "cardAddedSuccessfully": "添加卡成功",
    "validExpiration": "请输入有效的到期日期",
    "validCVV": "请输入有效的CVV码",
    "GoBack": "回去",
    "Confirmation": "确认",
    "NoPaymentInstrumentData": "未找到付款方式",
    "deactivatePayment": "您要停用所选的付款方式吗？",
    "accountVerified": "成功 ！！帐户详细信息已成功验证！",
    "accountNotVerified": "对不起 ！！无法验证帐户详细信息。",
    "validAmount": "请输入有效金额",
    "noConnectorFound": "没有可用的连接器",
    "noCountryFound": "未找到国家列表",
    "wentWrongText": "出了些问题！",
    "noStateFound": "未找到州列表",
    "noteText": "注意：使用测试存款流程进行银行账户验证最多需要 5 个工作日",
    "accountalreadyExists": "帐号已存在",
    "FeatureType": "特征类型",
    "validFeatureType": "请选择特征类型。",
    "NoPaymentChannel": "未找到支付渠道",
    "bankAddedsuccess": "银行账户添加成功",
    "bankAddedFailed": "添加银行账户失败",
    "limitBreach": "突破限制",
    "withdrawMethodSelect": "选择取款方式",
    "totalAmount": "总金额",
    "decimalpointErr": "小数点前最多接受 7 位数字。",
    "termsandCondition": "应接受条款和条件。",
    "agreeText": "我同意",
    "termsText": "条款和条件。",
    "minAmountTextAch": "最低提款金额为 ",
    "withdrawFeeText1": "提款手續費為 ",
    "withdrawFeeText2": " ",
    "maxAmountWithdrawTxt1": "每個月提款限額 ",
    "maxAmountWithdrawTxt2": " ",
    "withdrawSuccess": "提现成功！",
    "createAnotherPay": "创建另一个付款",
    "selectCurrency": "请选择货币",
    "lowPayquickerBalance": "您的 payquicker 余额太低，无法付款",
    "SelectPaymentMethod": "选择付款方式",
    "availablePayBalance": "可用的 Payquicker 余额",
    "fundsLoadedSuccess": "资金加载成功！",
    "invalidAccDetails": "帐户详细信息无效",
    "insufficientAccBalance": "账户余额不足",
    "invalidRecuDate": "选择的重复日期无效",
    "invalidTONum": "帐号无效",
    "invalidTrans": "无效交易",
    "counterPartyNotFound": "未找到交易对手",
    "ofacFailed": "OFAC 检查失败",
    "interSORFail": "不允许内部 SOR 交易",
    "invalidRoutingNum": "路由号码无效",
    "requestedServiceNotAvail": "当前请求的服务不可用",
    "limitExceedGlobal": "超出全局限制",
    "clientLimitExceed": "超过客户交易限额",
    "dailyLimitExceed": "超出每日交易限额",
    "invalidRecdetails": "收件人详细信息无效",
    "unabletoQuote": "无法获取报价",
    "enterRecipntPhone": "输入收件人电话号码",
    "enterAccNumber": "输入收件人帐号",
    "accountNotMatch": "帐号不匹配",
    "recipientAccount": "收件人帐户",
    "convertedAmount": "转换金额",
    "paymentSuccess": "付款创建成功！",
    "orderNumber": "订单号",
    "showing": "显示",
    "of": "的",
    "refNumber": "参考编号",
    "details": "细节",
    "NoTransactionsFound": "没有发现交易",
    "conntedTOQB": "已成功连接到 QuickBooks",
    "QuoteConvertedAmount": "总报价转换金额",
    "QBConnectFailed": "无法连接到 QuickBooks",
    "conenctingToQB": "正在连接到 QuickBooks，请稍候…",
    "synchronizeFailed": "无法同步 QuickBooks",
    "synchronizeSuccess": "成功同步 QuickBooks",
    "syncQBText": "与 QuickBooks Online 保持同步",
    "accountConnectedToQB": "帐户已连接到 QuickBooks",
    "Password": "密码",
    "profileChangesSaved": "您的个人资料更改已保存",
    "Timezone": "时区",
    "Locale": "语言环境",
    "passwordUpdateSuccess": "您的密码更改已成功保存",
    "passwordReqText": "密码必须符合指定要求",
    "passwordNotMatch": "您输入的两次密码不匹配",
    "newUserSuccess": "新用户创建成功",
    "addNewUser": "添加新用户",
    "ssoReq": "需要单点登录",
    "logoutTxt": "成功登出",
    "updatePaymentSuccess": "成功！成功更新付款方式",
    "updatePaymentFailed": "无法更新付款方式",
    "noDataFound": "没有找到数据",
    "submit": "提交",
    "completed": "完成",
    "failed": "失败",
    "pending": "待办",
    "sent": "已发送",
    "SessionExpired": "会话过期",
    "Uwallet": "Uwallet",
    "UWalletfunding": "U Wallet 资金",
    "debit": "借方",
    "credit": "信用/信贷",
    "CARD": "卡",
    "BANK": "银行",
    "active": "活跃",
    "ccintl": "国际信用卡",
    "amex_dis": "美国运通/探索卡",
    "amex_card_text": "添加美国运通卡",
    "nodata": "没有数据",
    "note": "笔记",
    "load_fund_via_card": "通过卡加载资金",
    "load_fund_via_ach": "通过ACH加载资金",
    "load_fund_via_connector": "通过连接器加载资金",
    "p2m_via_connector": "P2M 通过连接器",
    "creditAdjustment": "信贷调整",
    "debitAdjustment": "借方调整",
    "startDateText": "开始日期",
    "endDateText": "结束日期",
    "payIn": "支付",
    "payOut": "支出",
    "checking": "查询",
    "saving": "保存",
    "verify": "核实",
    "reset": "重置",
    "today": "今天",
    "a2a_reversal": "A2A逆转",
    "credit_point": "信用积分",
    "commission_payout_via_ach": "通过ACH支付佣金",
    "commission_payout_via_uWallet": "通过UWallet支付佣金",
    "commission_payout_via_payquicker": "通过Payquicker支付佣金",
    "withdraw_via_ach": "通过ACH提款",
    "withdraw_via_payquicker": "通过Payquicker取款",
    "credit_reversal": "信用逆转",
    "load_fund_via_card_reversal": "通过逆转卡加载资金",
    "uwallet_funding_reversal": "U Wallet 资金逆转",
    "processing": "加工",
    "declined": "谢绝",
    "onHold": "等候接听",
    "scheduled": "已预定",
    "cancelled": "取消",
    "beneficiaryName": "受益人姓名",
    "b_b_code": "银行代码和分行代码",
    "validBankCode": "请输入有效代码",
    "minBankCode": "代码不能少于 7 位",
    "load_fund_wallet": "载入资金钱包",
    "ach_transfer": "ACH转账",
    "mater_visa": "万事达卡/Visa卡",
    "go_to_transaction": "前往交易",
    "p2m_reversal": "P2M撤销",
    "deposit_account_list": "存款账户列表",
    "load_fund_via_ach_reversal": "通过 ACH 转存资金",
    "load_fund_via_connector_reversal": "通过连结转存资金",
    "KYC_Failed": "KYC验证失败",
    "updateKYCText": "您要更新 KYC 详细信息吗？",
    "userType": "用户类型",
    "usertypeErr": "请选择用户类型。",
    "recidencyNumberTxt": "居住号码",
    "validResNum": "需要居住号码",
    "validresidencyNum": "请输入有效的居住号码",
    "flexText": "PayQuicker Flex",
    "flexTextVietnam": "Thanh toán linh hoạt",
    "withdrawalConfig": "取款配置",
    "withdrawalConfiguration": "取款配置",
    "addConfig": "添加配置",
    "addFreequencyConfig": "添加/编辑频率配置",
    "ReceivingAccount": "收款账户",
    "deactivateConfig": "您要停用选定的配置吗？",
    "updateConfigSuccess": "成功！ 更新配置成功",
    "updateConfigFailed": "更新配置失败",
    "add_card_text": "添加信用卡",
    "UWalletTransfer": "U钱包转账",
    "daily": '日常的',
    "weekly": "每周",
    "monthly": "每月",
    "biweekly": "双周刊",
    "immediate": "即时",
    "percentage": "百分比",
    "percentError": "请输入有效百分比",
    "toDistributor": "致经销商",
    "distributorId": "请输入经销商编号",
    "invalidDistributor": "请输入有效的经销商 ID",
    "distributorNotFound": "找不到经销商",
    "distributorKYCError": "收件人分销商未验证",
    "decimalNotPermit": "不允许有小数位",
    "paybyCard": "刷卡支付",
    "validIDNum": "请输入有效号码",
    "swiftCode": "斯威夫特代码",
    "validBankName": "请输入有效的银行名称",
    "JAPANPAYOUT": "银行",
    "idNumber": "身份证号",
    "commissionHistory": "佣金历史",
    "CITCON WITHDRAWAL": "银行提款",
    "validCityName": "请输入有效的城市名称",
    "pendingApproval": "待批准",
    "purposeCode": "付款目的",
    "plsSeletpurposeCode": "请选择付款用途",
    "QualifyingCredit": "合格信用",
    "tooltipAccountName": "请输入您的全名，包括名字和姓氏（如果是个人），例如 John Smith 或您的法定公司全名（如果是公司），例如 ABC Limited",
    "tooltipAccountNumber": "输入银行帐号",
    "tooltipBankCode": "输入银行代码",
    "tooltipBranchCode": "输入分行代码",
    "tooltipSwiftBic": "请输入您银行的 SWIFT BIC（银行识别码），长度可以是 8 或 11 个字符。 如果 SWIFT BIC 只有 8 个字符，请勿通过添加 XXX 填充至 11 个字符",
    "tooltipAddress": "请输入您的住宅（如果是个人）或注册的（如果是公司）门牌号/名称和街道名称，例如 1 Main Street",
    "tooltipCity": "请输入您的居住地址（如果是个人）或注册地址（如果是公司）的城市或城镇，例如伦敦",
    "tooltipZipcode": "请输入您的居住地址（如果是个人）或注册地址（如果是公司）的邮政编码",
    "tooltipCountry": "请选择您的居住地址（如果是个人）或注册地址（如果是公司）所在的国家/地区",
    "tooltipDOB": "请选择您的出生日期（如果仅限个人）",
    "tooltipBankName": "请输入您的银行名称",
    "bankCode": "银行代码",
    "branchCode": "分行代码",
    "dob": "出生日期",
    "selct_currency_deposit": "币别",
    "pc_from_text": "選擇轉出信用卡",
    "pc_to_text": "選擇存入UW帳號​",
    "pc_amount_text": "金额",
    "CNY PAYOUT": "Bank (WP)",
    "accountHolderaddress": "账户持有人地址",
    "accountHolderName": "账户持有人名称",
    "branchName": "开户行支行名称(选填)",
    "continue": "继续",
    "validBranchName": "请输入有效的分行名称",
    "SOLU": "銀行提款",
    "cardUpdatedSuccessfully": "卡更新成功",
    "reason": "原因",
    "failedUpdateCard": "卡更新失败",
    "requiredError": "必填栏位",
    "kycNotVerified": "KYC 尚未验证。",
    "VND PAYOUT": "提取 WP",
    "phoneNumberAliWP": "86 XXX XXXX XXXX",
    "phoneTooltip": "请提供受益人的电话号码（示例：86 XXX XXXX XXXX）",
    "emailTooltip": "请输入受益人的电子邮件地址",
    "ALIPAY PAYOUT WP": "Alipay",

    "enterValidAccNameWithLimit": "Enter Valid Payee name with minimum 2 and maximum 70 characters",
    "enterValidAccNumWithLimit": "Enter Valid account number with minimum 4 and maximum 17 characters",
    "enterValidRoutingNumWithLimit": "Enter Valid routing number",
    "enterValidAddressWithLimit": "Enter Valid Address with minimum 2 and maximum 70 characters",
    "enterValidCityWithLimit": "Enter Valid City with minimum 2 and maximum 35 characters",
    "enterValidZipcodeWithLimit": "Enter Valid Zip code with minimum 2 and maximum 10 characters",

    "enterValidAccNameWithChinese": "Enter valid name in Chinese Characters",
    "enterValidBankNameWithChinese": "Enter valid Bank name in Chinese Characters",
    "enterValidBranchNameWithChinese": "Enter valid Branch name in Chinese Characters",
    "enterValidSoluAccNum": "Enter Valid numeric account number",
    "enterValidSoluAccNumWith20Limit": "Enter Valid numeric account number maximum 20 characters.",
    "enterValidSoluAccNumWith17Limit": "Enter Valid numeric account number maximum 17 characters.",
    "enterValidSoluPhoneNumWithLimit": "Enter Valid numeric phone number maximum 20 characters.",
    "enterValidSoluIDNumWithLimit": "Enter Valid alphanumeric ID number maximum 100 characters.",

    "enterValidAlipayPayeeNameWithLimit": "Enter Valid Payee name with minimum 2 and maximum 35 characters",
    "enterValidAlipayPhoneNumWithLimit": "Enter Valid numeric phone number with minimum = 5 maximum 34 characters.",
    "enterValidAlipayEmailWithLimit": "Enter Valid Email address  with minimum = 6 maximum 100 characters.",

    "enterValidThanhAccNameWithChinese": "Enter Valid  account name with minimum 1 and maximum 140 characters.",
    "enterValidThanhAccNumWithLimit": "Enter Valid  account number  with minimum 1 and maximum 30 characters.",
    "enterValidThanhSwiftCodeWithLimit": "Enter Valid swift code with minimum 8 and maximum 11 characters.",
    "enterValidThanhAddressWithLimit": "Enter Valid  address  with minimum 2 and maximum 35 characters.",

    "enterValidBeneficiaryNameWithLimit": "Enter valid name with maximum 20 characters.",
    "enterValidTWDAccNumWithLimit": "Enter valid account number with maximum 20 characters.",
    "enterValidTWDBranchCodeWithLimit": "Enter valid branch code with maximum 7 characters.",

    "enterValidMYRAccNameWithLimit": "Enter valid name with minimum = 2 and maximum 50 characters.",
    "enterValidMYRAccNumWithLimit": "Enter valid numeric account number with maximum 16 characters.",

    "enterValidTHBAccNameWithLimit": "Enter valid name with maximum 35 characters.",
    "enterValidTHBAccNumWithLimit": "Enter valid numeric account number  with  maximum 12 characters.",
    "enterValidTHBSwiftCodeWithLimit": "Enter valid swift code with maximum 11 characters.",
    "enterValidTHBAddressWithLimit": "Enter valid Address with minimum = 5 and maximum 35 characters.",
    "enterValidTHBCityWithLimit": "Enter valid city with maximum 60 characters.",
    "enterValidTHBZipcodeWithLimit": "Enter valid zip code with minimum = 5 and maximum 10 characters.",

    "enterValidGBPPayeeNameWithLimit": "Enter valid name with Minimum 3 and maximum 50 characters.",
    "enterValidGBPIbanWithLimit": "Enter valid Iban with maximum 22 characters.",
    "enterValidGBPBankNameWithLimit": "Enter valid Bank name with Minimum = 1 and maximum 50 characters.",

    "enterValidEURAccNameWithLimit": "Enter valid name with Minimum 1 and maximum 50 characters.",
    "enterValidEURAccNumWithLimit": "Enter valid numeric account number  with  Minimum 1 and maximum 35 characters.",
    "enterValidEURBankNameWithLimit": "Enter valid Bank name with Minimum = 2 and maximum 10 characters.",

    "enterValidJPYBankNameWithLimit": "Enter valid bank name with  maximum 35 characters.",
    "acceptTermsandconditions": "I followed the guide and set up my Alipay account to receive funds",
    "failedDuplicateCard": "Card addition failed as card already exist.",
    "updatedDate": "Updated Date"
}