module.exports = {
    "users": "ผู้ใช้",
    "recurringTrans": "การทำธุรกรรมที่เกิดซ้ำ",
    "firstName": "ชื่อแรก",
    "lastName": "นามสกุล",
    "emailAddress": "ที่อยู่อีเมล",
    "newPass": "รหัสผ่านใหม่",
    "confirmPass": "ยืนยันรหัสผ่าน",
    "availableBalance": "ยอดเงินคงเหลือ",
    "currentBalance": "ยอดเงินปัจจุบัน",
    "clientName": "ชื่อลูกค้า",
    "createdBy": "สร้างโดย",
    "accountReference": "การอ้างอิงบัญชี",
    "routingReference": "การอ้างอิงเราติ้ง",
    "expiryDate": "วันหมดอายุ",
    "recipientEmail": "อีเมผู้รับ",
    "startDate": "วันที่เริ่มต้น",
    "reference": "อ้างอิง",
    "transactionType": "ประเภทธุรกรรม",
    "name": "ชื่อ",
    "role": "บทบาท",
    "accounts": "บัญชี ",
    "transactionReference": "การอ้างอิงธุรกรรม",
    "parentReference": "ผู้ปกครองที่อ้างอิง",
    "paymentReference": "หลักฐานการชำระเงิน",
    "amount": "จำนวนเงิน",
    "invoiceNumber": "เลขใบสั่งของ",
    "feeAmount": "จำนวนค่าธรรมเนียม",
    "exciseTax": "ภาษีสรรพสามิต",
    "salesTax": "ภาษีการขาย",
    "registrationFee": "ค่าลงทะเบียน",
    "serviceFee": "ค่าบริการ",
    "type": "ประเภท",
    "recurringReference": "การอ้างอิงที่เกิดซ้ำ",
    "status": "สถานะ",
    "description": "คำอธิบาย",
    "currency": "สกุลเงิน",
    "frequency": "ความถี่",
    "accountNumber": "หมายเลขบัญชี",
    "fileName": "ชื่อไฟล์",
    "createdDate": "วันที่สร้าง",
    "dueDate": "วันครบกำหนด",
    "action": "ลงมือปฏิบัติ",
    "email": "อีเมล ",
    "phoneNumber": "หมายเลขโทรศัพท์",
    "bankName": "ชื่อธนาคาร",
    "fromAccountNumber": "จากหมายเลขบัญชี",
    "nameonCard": "ชื่อบนบัตร",
    "zipCode": "รหัสไปรษณีย์",
    "accountName": "ชื่อบัญชี",
    "routingNumber": "หมายเลขเราติ้ง",
    "accountType": "ประเภทบัญชี",
    "address": "ที่อยู่ ",
    "city": "เมือง",
    "country": "ประเทศ",
    "state": "จังหวัด",
    "debitAmount": "จำนวนเดบิต",
    "firstCreditAmount": "จำนวนเครดิตแรก",
    "secondCreditAmount": "จำนวนเครดิตที่สอง",
    "referenceID": "รหัสอ้างอิง",
    "remarks": "หมายเหตุ",
    "toAccountNumber": "ไปที่หมายเลขบัญชี",
    "addCard": "เพิ่มบัตร ",
    "create": "สร้าง",
    "cancel": "ยกเลิก",
    "forgotPassword": "ลืมรหัสผ่าน",
    "enterOtp": "ระบุรหัสยืนยืน",
    "resendCode": "ส่งรหัสใหม่",
    "validZipCode": "กรุณากรอกรหัสไปรษณีย์ที่ถูกต้อง",
    "transactions": "ธุรกรรม ",
    "select": "เลือก",
    "CardType": "ประเภทบัตร",
    "CardNumber": "หมายเลขบัตร",
    "ExpirationDate": "วันหมดอายุ",
    "CVV": "CVV",
    "GoBack": "กลับไป",
    "Confirmation": "การยืนยัน ",
    "wentWrongText": "บางอย่างผิดพลาด!",
    "showing": "กำลังแสดง",
    "of": "ของ",
    "Password": "รหัสผ่าน",
    "submit": "ส่ง",
    "CARD": "บัตร",
    "BANK": "ธนาคาร",
    "startDateText": "วันที่เริ่มต้น",
    "credit_point": "คะแนนเครดิต",
    "JAPANPAYOUT": "ธนาคาร",
    "commissionHistory": "ประวัติคอมมิชชั่น",
    "selct_currency_deposit": "สกุลเงิน",
    "pc_amount_text": "จำนวนเงิน",
    "pc_accountNumber": "หมายเลขบัญชี",
    "requiredError": "ต้องระบุข้อมูลในช่องนี้",
    "dashboard": "แดชบอร์ด",
    "myAccounts": "เครดิตของฉัน",
    "internationalTransfer": "โอนเงินระหว่างประเทศ",
    "fundingType": "บัตรและบัญชี",
    "deposit": "ซื้อเครดิต",
    "withdraw": "ถอนเงิน",
    "invoice": "ใบแจ้งหนี้",
    "requestMoney": "ขอเงิน",
    "recipients": "ผู้รับ",
    "developers": "นักพัฒนา",
    "myProfile": "โปรไฟล์ของฉัน",
    "logout": "ออกจากระบบ",
    "qucikBooks": "คิวบุ๊ค",
    "profileDetails": "รายละเอียดโปรไฟล์",
    "password": "รหัสผ่าน",
    "qbDownloads": "ดาวน์โหลด QB",
    "transfer": "โอนเงิน",
    "allTransactions": "ธุรกรรมของฉัน",
    "userRole": "บทบาทของผู้ใช้",
    "settings": "ตั้งค่า",
    "oldPass": "รหัสผ่านเก่า",
    "passwordRequirements": "ข้อกำหนดของรหัสผ่าน",
    "reqOne": "อย่างน้อย 8 ตัวอักษร",
    "reqTwo": "อย่างน้อย 1 ตัวอักษรตัวพิมพ์เล็ก",
    "reqThree": "อย่างน้อย 1 ตัวอักษรตัวพิมพ์ใหญ่",
    "reqFour": "อย่างน้อย 1 ตัวเลข",
    "reqFive": "อย่างน้อย 1 อักขระพิเศษ",
    "reqSix": "อย่างน้อย 1 อักขระพิเศษ",
    "reqSeven": "ไม่มีช่องว่าง",
    "saveChanges": "บันทึกการเปลี่ยนแปลง",
    "onlyIntAccounts": "บัญชีระหว่างประเทศเท่านั้น",
    "lastUpdatedDate": "วันที่อัปเดตล่าสุด",
    "channel": "ช่องทาง",
    "webhook": "เว็บฮุค",
    "recipientName": "ชื่อผู้รับ",
    "aCHAccountNumber": "หมายเลขบัญชี ACH",
    "wireAccountNumber": "หมายเลขบัญชีการโอนเงิน",
    "aPIKey": "คีย์ API",
    "transactionID": "รหัสธุรกรรม",
    "sourceType": "ประเภทที่มา",
    "creationDate": "วันที่สร้าง",
    "phone": "โทรศัพท์",
    "complete": "สมบูรณ์",
    "failure": "ล้มเหลว",
    "returns": "การคืน",
    "count": "จำนวน",
    "noAccounts": "ไม่มีบัญชี",
    "transactionsType": "ประเภทธุรกรรม",
    "mostRecentTransactions": "ธุรกรรมล่าสุด",
    "export": "ส่งออก",
    "transactionHistory": "ประวัติธุรกรรม",
    "taxAmount": "จำนวนภาษี",
    "conversionRate": "อัตราแลกเปลี่ยน",
    "oFACMatch": "การจับคู่ OFAC",
    "created": "สร้างแล้ว",
    "account": "บัญชี",
    "receiver": "ผู้รับ",
    "paymentType": "ประเภทการชำระเงิน",
    "from": "จาก",
    "sendVia": "ส่งผ่าน",
    "recipientEmailAddress": "ที่อยู่อีเมลผู้รับ",
    "recipientPhoneNumber": "หมายเลขโทรศัพท์ผู้รับ",
    "recipientAccountNumber": "หมายเลขบัญชีผู้รับ",
    "confirmAccountNumber": "ยืนยันหมายเลขบัญชี",
    "to": "ถึง",
    "direction": "ทิศทาง",
    "paymentDate": "วันที่ชำระเงิน",
    "service": "บริการ",
    "endDate": "วันที่สิ้นสุด",
    "numberofTransfers": "จำนวนการโอน",
    "internalDescription": "คำอธิบายภายใน",
    "recipient_email": "อีเมลผู้รับ",
    "memo": "บันทึก",
    "recipientType": "ประเภทผู้รับ",
    "verificationType": "ประเภทการตรวจสอบ",
    "nickName": "ชื่อเล่น",
    "beneficiaryBankCode": "รหัสธนาคารผู้รับ",
    "addressLine1": "ที่อยู่บรรทัดที่ 1",
    "addressLine2": "ที่อยู่บรรทัดที่ 2",
    "paymentMethod": "วิธีการชำระเงิน",
    "aCHDetails": "รายละเอียด ACH",
    "wireDetails": "รายละเอียดการโอนเงิน",
    "internationalTransferDetails": "รายละเอียดการโอนเงินระหว่างประเทศ",
    "selectDate": "เลือกวันที่",
    "sender": "ผู้ส่ง",
    "buyorSell": "ซื้อหรือขาย",
    "paymentInstrument": "เครื่องมือการชำระเงิน",
    "fromaccount": "จากบัญชี",
    "toCounterparty": "ถึงคู่ค้า",
    "purposeofPayment": "วัตถุประสงค์ของการชำระเงิน",
    "fXRate": "อัตราแลกเปลี่ยน",
    "fXAmount": "จำนวนเงินแลกเปลี่ยน",
    "fXCurrency": "สกุลเงินแลกเปลี่ยน",
    "toName": "ชื่อผู้รับ",
    "toAccount": "บัญชีผู้รับ",
    "nextTransactionDate": "วันที่ธุรกรรมถัดไป",
    "aPICode": "รหัส API",
    "publicKey": "คีย์สาธารณะ",
    "privateKey": "คีย์ส่วนตัว",
    "optional": "ไม่บังคับ",
    "additionalInformation": "ข้อมูลเพิ่มเติม",
    "createPayment": "สร้างการชำระเงิน",
    "downloadPDF": "ดาวน์โหลด PDF",
    "resend": "ส่งอีกครั้ง",
    "approve": "อนุมัติ",
    "decline": "ปฏิเสธ",
    "addBankAccount": "เพิ่มบัญชีธนาคาร",
    "addNewRecipient": "เพิ่มผู้รับใหม่",
    "synchronizeQuickBooks": "ซิงโครไนซ์คิวบุ๊ค",
    "newUser": "ผู้ใช้ใหม่",
    "generate": "สร้าง",
    "wITHDRAW": "ถอนเงิน ",
    "confirm": "ยืนยัน ",
    "close": "ปิด ",
    "sendResetInstructions": "ส่งคำแนะนำการรีเซ็ต ",
    "logIn": "เข้าสู่ระบบ ",
    "resetPassword": "รีเซ็ตรหัสผ่าน ",
    "setPassword": "ตั้งรหัสผ่าน ",
    "signUp": "ลงทะเบียน",
    "backToLogin": "กลับไปที่หน้าเข้าสู่ระบบ",
    "selectPayment": "เลือกประเภทการชำระเงิน",
    "scheduleRecurringTransaction": "กำหนดการธุรกรรมที่เกิดซ้ำ",
    "forgotPassText": "ใส่อีเมลที่เกี่ยวข้องกับบัญชีของคุณและเราจะส่งอีเมลพร้อมคำแนะนำในการรีเซ็ตรหัสผ่านของคุณ.",
    "unableToCP": "ไม่สามารถสร้างการชำระเงินได้",
    "plsSelectPaymentTyp": "โปรดเลือกประเภทการชำระเงิน",
    "plsSelectFromAcc": "โปรดเลือกบัญชีต้นทาง",
    "selectPaymentPayment": "โปรดเลือกประเภทการชำระเงิน",
    "enterEmail": "โปรดใส่ที่อยู่อีเมล",
    "emailValidation": "ที่อยู่อีเมลต้องมีไม่เกิน 320 ตัวอักษร",
    "enterPhone": "โปรดใส่หมายเลขโทรศัพท์ที่ถูกต้อง",
    "enterAccNum": "โปรดใส่หมายเลขบัญชี",
    "AccNum": "โปรดใส่หมายเลขบัญชี",
    "selectRecipientName": "โปรดเลือกชื่อผู้รับ",
    "enterAmounttoTransfer": "โปรดใส่จำนวนเงินที่ต้องการโอน",
    "enterCurrency": "โปรดใส่สกุลเงินที่ต้องการโอน",
    "selectDirection": "โปรดเลือกทิศทาง",
    "selectPaymentDate": "โปรดเลือกวันที่ชำระเงิน",
    "selectPaymentdate": "โปรดเลือกวันที่ชำระเงิน",
    "selectServiceType": "โปรดเลือกประเภทบริการ",
    "selectFrequency": "โปรดเลือกความถี่",
    "enterAmount": "โปรดใส่จำนวนเงิน",
    "selectTxnDate": "โปรดเลือกวันที่ธุรกรรม",
    "selectRecipientType": "โปรดเลือกประเภทผู้รับ",
    "enterEmailAddress": "โปรดใส่ที่อยู่อีเมล",
    "validEmail": "โปรดใส่ที่อยู่อีเมลที่ถูกต้อง",
    "EmailValidation": "ที่อยู่อีเมลต้องมีไม่เกิน 320 ตัวอักษร",
    "selectFrmAcc": "โปรดเลือกบัญชีต้นทาง",
    "invoiceAlpha": "หมายเลขใบแจ้งหนี้ควรเป็นตัวอักษรและตัวเลข",
    "enterInvoiceNum": "โปรดใส่หมายเลขใบแจ้งหนี้",
    "invoiceValidation": "หมายเลขใบแจ้งหนี้ต้องมีไม่เกิน 150 ตัวอักษร",
    "amountToTransfer": "โปรดใส่จำนวนเงินที่ต้องการโอน",
    "valueForMemo": "โปรดใส่ค่าสำหรับบันทึก",
    "memoValidation": "บันทึกต้องมีไม่เกิน 255 ตัวอักษร",
    "selectPaymentType": "โปรดเลือกประเภทการชำระเงิน",
    "enterName": "โปรดใส่ชื่อ",
    "ValidName": "โปรดใส่ชื่อที่ถูกต้อง",
    "nameValidation": "ชื่อต้องมีอย่างน้อย 3 ตัวอักษร",
    "Name": "โปรดใส่ชื่อ",
    "validname": "โปรดใส่ชื่อที่ถูกต้อง",
    "nameValid": "ชื่อต้องมีอย่างน้อย 3 ตัวอักษร",
    "enterZipCode": "โปรดใส่รหัสไปรษณีย์",
    "validzipcode": "รหัสไปรษณีย์ต้องมีอย่างน้อย 5 หลัก",
    "validAccName": "โปรดใส่ชื่อบัญชี",
    "enterValidAccName": "โปรดใส่ชื่อบัญชีที่ถูกต้อง",
    "maxAccName": "ชื่อบัญชีต้องมีไม่เกิน 20 ตัวอักษร",
    "minAccName": "ชื่อบัญชีต้องมีอย่างน้อย 3 ตัวอักษร",
    "accNum": "โปรดใส่หมายเลขบัญชี",
    "validAccNum": "โปรดใส่หมายเลขบัญชีที่ถูกต้อง",
    "maxAccNum": "หมายเลขบัญชีต้องมีไม่เกิน 20 ตัวอักษร",
    "japanMaxAccNum": "หมายเลขบัญชีต้องมีไม่เกิน 35 ตัวอักษร",
    "japanMaxBankName": "ชื่อธนาคารต้องมีไม่เกิน 35 ตัวอักษร",
    "japanMinSwiftCode": "รหัส Swift ควรมีอย่างน้อย 8 ตัวอักษร",
    "japanMinAddress": "ที่อยู่ต้องมีอย่างน้อย 2 ตัวอักษร",
    "japanMaxCity": "เมืองต้องมีไม่เกิน 35 ตัวอักษร",
    "minAccNum": "หมายเลขบัญชีต้องมีอย่างน้อย 4 ตัวอักษร",
    "routingNum": "โปรดใส่หมายเลข Routing",
    "validRoutingNum": "โปรดใส่หมายเลข Routing ที่ถูกต้อง",
    "minRoutingNum": "หมายเลข Routing ต้องมีอย่างน้อย 9 ตัวอักษร",
    "ValidRoutingNum": "โปรดใส่หมายเลข Routing ที่ถูกต้อง",
    "Acctype": "โปรดเลือกประเภทบัญชี",
    "validAddress": "โปรดใส่ที่อยู่ที่ถูกต้อง",
    "maxAddress": "ที่อยู่ต้องมีไม่เกิน 400 ตัวอักษร",
    "minAddress": "ที่อยู่ต้องมีอย่างน้อย 5 ตัวอักษร",
    "City": "โปรดใส่ชื่อเมืองของคุณ",
    "maxCity": "เมืองต้องมีไม่เกิน 60 ตัวอักษร",
    "countryName": "โปรดเลือกชื่อประเทศ",
    "stateName": "โปรดใส่รัฐที่ถูกต้อง",
    "validzipCode": "โปรดใส่รหัสไปรษณีย์ที่ถูกต้อง",
    "selectVerificationType": "โปรดเลือกประเภทการตรวจสอบ",
    "debitAmnt": "โปรดใส่จำนวนเงินหัก",
    "creditAmnt": "โปรดใส่จำนวนเงินเครดิต",
    "CreditAmount": "โปรดใส่จำนวนเงินเครดิต",
    "AccName": "โปรดใส่ชื่อบัญชี",
    "recipienttype": "โปรดเลือกประเภทผู้รับ",
    "accNumber": "โปรดใส่หมายเลขบัญชี",
    "validAccnum": "โปรดใส่หมายเลขบัญชีที่ถูกต้อง",
    "maxAccNumber": "หมายเลขบัญชีต้องมีไม่เกิน 20 ตัวอักษร",
    "minAccNumber": "หมายเลขบัญชีต้องมีอย่างน้อย 4 ตัวอักษร",
    "beneficiaryBankcode": "โปรดใส่รหัสธนาคารผู้รับผลประโยชน์",
    "minRoutingNumber": "หมายเลข Routing ต้องมีอย่างน้อย 9 ตัวอักษร",
    "acountNumber": "โปรดใส่หมายเลขบัญชี",
    "maxAccnum": "หมายเลขบัญชีต้องมีไม่เกิน 20 ตัวอักษร",
    "minAccnum": "หมายเลขบัญชีต้องมีอย่างน้อย 4 ตัวอักษร",
    "routingnum": "โปรดใส่หมายเลข Routing",
    "maxRoutingnum": "หมายเลข Routing ต้องมีอย่างน้อย 9 ตัวอักษร",
    "acctype": "โปรดเลือกประเภทบัญชี",
    "accountNum": "โปรดใส่หมายเลขบัญชี",
    "validaccountNum": "โปรดใส่หมายเลขบัญชีที่ถูกต้อง",
    "maxAccountNum": "หมายเลขบัญชีต้องมีไม่เกิน 20 ตัวอักษร",
    "minAccountNum": "หมายเลขบัญชีต้องมีอย่างน้อย 4 ตัวอักษร",
    "enterRoutingNum": "โปรดใส่หมายเลข Routing",
    "minRouting": "หมายเลข Routing ต้องมีอย่างน้อย 9 ตัวอักษร",
    "enterAddressLine1": "โปรดใส่ที่อยู่บรรทัดที่ 1",
    "maxAddrss": "ที่อยู่ต้องมีไม่เกิน 100 ตัวอักษร",
    "minAddrss": "ที่อยู่ต้องมีอย่างน้อย 5 ตัวอักษร",
    "enterCity": "โปรดใส่ชื่อเมือง",
    "selectCountryName": "โปรดเลือกชื่อประเทศ",
    "selectStateName": "โปรดเลือกชื่อรัฐ",
    "validZipcode": "โปรดใส่รหัสไปรษณีย์ที่ถูกต้อง",
    "valdAccnum": "โปรดใส่หมายเลขบัญชีที่ถูกต้อง",
    "countryname": "โปรดเลือกชื่อประเทศ",
    "statename": "โปรดใส่ชื่อรัฐที่ถูกต้อง",
    "selectpaymentMethod": "โปรดเลือกวิธีการชำระเงิน",
    "validPhone": "โปรดใส่หมายเลขโทรศัพท์ที่ถูกต้อง",
    "inpFname": "โปรดป้อนชื่อของคุณ",
    "inpLname": "โปรดป้อนนามสกุลของคุณ",
    "inpEmail": "โปรดป้อนอีเมลของคุณ",
    "valiemail": "โปรดใส่อีเมลที่ถูกต้อง",
    "validFname": "โปรดใส่ชื่อจริงที่ถูกต้อง",
    "maxFname": "ชื่อจริงต้องมีไม่เกิน 50 ตัวอักษร",
    "minFname": "ต้องมีอย่างน้อย 3 ตัวอักษร",
    "validLname": "โปรดใส่นามสกุลที่ถูกต้อง",
    "maxLname": "นามสกุลต้องมีไม่เกิน 50 ตัวอักษร",
    "minLname": "ต้องมีอย่างน้อย 3 ตัวอักษร",
    "phoneNum": "โปรดใส่หมายเลขโทรศัพท์",
    "validPhoneNum": "โปรดใส่หมายเลขโทรศัพท์ที่ถูกต้อง",
    "maxPhone": "หมายเลขโทรศัพท์ต้องมีไม่เกิน 10 ตัวเลข",
    "minPhone": "หมายเลขโทรศัพท์ต้องมี 10 ตัวเลข",
    "fromAcc": "โปรดเลือกจากบัญชี",
    "selectstartDate": "โปรดเลือกวันที่เริ่มต้น",
    "newPsw": "บันทึกรหัสผ่านใหม่แล้ว",
    "unablePsw": "ไม่สามารถอัปเดตรหัสผ่านได้",
    "inpOldPsw": "โปรดป้อนรหัสผ่านเก่าของคุณ",
    "inpNewPsw": "โปรดป้อนรหัสผ่านใหม่ของคุณ",
    "maxPsw": "รหัสผ่านต้องมีความยาวมากกว่า 8 ตัวอักษร",
    "newPassword": "โปรดยืนยันรหัสผ่านใหม่ของคุณ",
    "result": "ผลลัพธ์",
    "buyOrsell": "โปรดเลือกซื้อหรือขาย",
    "frmAccNum": "โปรดเลือกจากหมายเลขบัญชี",
    "selectaccNum": "โปรดเลือกไปยังหมายเลขบัญชี",
    "paymentInst": "โปรดเลือกเครื่องมือชำระเงิน",
    "instType": "โปรดเลือกประเภทเครื่องมือ",
    "insufficientFunds": "เงินไม่เพียงพอ",
    "unablePayment": "ไม่สามารถสร้างการชำระเงินได้",
    "selectfundingType": "โปรดเลือกบัตรและบัญชี",
    "amntTrans": "โปรดป้อนจำนวนเงินที่ต้องการโอน",
    "counterParty": "โปรดเลือกคู่ค้า",
    "purposePayment": "โปรดเลือกวัตถุประสงค์ของการชำระเงิน",
    "toAcc": "โปรดเลือกไปยังบัญชี",
    "validUrl": "โปรดป้อน URL ที่ถูกต้อง",
    "all": "ทั้งหมด",
    "loading": "กำลังโหลด...",
    "noAccountData": "ไม่พบข้อมูลบัญชี",
    "creditTransfer": "โอนเงินเข้าบัญชี",
    "noTransactionData": "ไม่พบข้อมูลธุรกรรม",
    "productCredit": "เครดิตผลิตภัณฑ์",
    "refresh": "รีเฟรช",
    "UWallets": "UWallets",
    "UWalletNumber": "หมายเลข UWallet",
    "CreditID": "รหัสเครดิต",
    "InstrumentType": "ประเภทเครื่องมือ",
    "SelectCurrency": "เลือกสกุลเงิน",
    "pleaseselecttheCardtype": "โปรดเลือกประเภทบัตร",
    "validCardNumber": "โปรดป้อนหมายเลขบัตรที่ถูกต้อง",
    "failedAddCard": "เพิ่มบัตรล้มเหลว",
    "cardAddedSuccessfully": "เพิ่มบัตรสำเร็จ",
    "validExpiration": "โปรดป้อนวันที่หมดอายุที่ถูกต้อง",
    "validCVV": "โปรดป้อนรหัส CVV ที่ถูกต้อง",
    "NoPaymentInstrumentData": "ไม่พบเครื่องมือชำระเงิน",
    "deactivatePayment": "คุณต้องการปิดใช้งานเครื่องมือชำระเงินที่เลือกหรือไม่?",
    "accountVerified": "สำเร็จ !! ตรวจสอบรายละเอียดบัญชีสำเร็จ !!",
    "accountNotVerified": "ขออภัย !! ไม่สามารถตรวจสอบรายละเอียดบัญชีได้",
    "validAmount": "โปรดป้อนจำนวนเงินที่ถูกต้อง",
    "noConnectorFound": "ไม่มีวิธีการชำระเงินที่พร้อมใช้งานในเวลานี้",
    "noCountryFound": "ไม่พบรายการประเทศ",
    "noStateFound": "ไม่พบรายการสถานะ",
    "noteText": "หมายเหตุ: การยืนยันบัญชีธนาคารโดยใช้กระบวนการฝากเงินทดลองจะใช้เวลาสูงสุด 5 วันทำการ",
    "accountalreadyExists": "หมายเลขบัญชีมีอยู่แล้ว",
    "FeatureType": "ประเภทคุณลักษณะ",
    "validFeatureType": "โปรดเลือกประเภทคุณลักษณะ",
    "NoPaymentChannel": "ไม่พบช่องทางการชำระเงิน",
    "bankAddedsuccess": "เพิ่มบัญชีธนาคารเรียบร้อยแล้ว",
    "bankAddedFailed": "เพิ่มบัญชีธนาคารล้มเหลว",
    "limitBreach": "ขีดจำกัดถูกละเมิด",
    "withdrawMethodSelect": "เลือกวิธีการถอน",
    "totalAmount": "จำนวนเงินรวม",
    "decimalpointErr": "รับได้สูงสุด 7 หลักก่อนจุดทศนิยม",
    "termsandCondition": "ควรยอมรับข้อกำหนดและเงื่อนไข",
    "agreeText": "ฉันเห็นด้วยกับ",
    "termsText": "ข้อกำหนดและเงื่อนไข",
    "minAmountTextAch": "ยอดถอนขั้นต่ำคือ ",
    "withdrawFeeText1": "มีค่าธรรมเนียมการถอนเงินจำนวน ",
    "withdrawFeeText2": " จะถูกเรียกเก็บ",
    "maxAmountWithdrawTxt1": "ยอดถอนสูงสุดคือ ",
    "maxAmountWithdrawTxt2": " ต่อคำขอ",
    "withdrawSuccess": "ถอนเงินสำเร็จแล้ว!",
    "createAnotherPay": "สร้างการชำระเงินอื่น",
    "selectCurrency": "โปรดเลือกสกุลเงิน",
    "lowPayquickerBalance": "ยอด Payquicker ของคุณน้อยเกินไปที่จะทำการชำระเงิน",
    "SelectPaymentMethod": "เลือกวิธีการชำระเงิน",
    "availablePayBalance": "ยอด Payquicker ที่ใช้ได้",
    "fundsLoadedSuccess": "โอนเงินสำเร็จแล้ว!",
    "invalidAccDetails": "รายละเอียดบัญชีไม่ถูกต้อง",
    "insufficientAccBalance": "ยอดคงเหลือในบัญชีไม่เพียงพอ",
    "invalidRecuDate": "เลือกวันที่เกินวันที่ทำซ้ำที่ถูกต้อง",
    "invalidTONum": "หมายเลขบัญชีที่เป้าหมายไม่ถูกต้อง",
    "invalidTrans": "ธุรกรรมไม่ถูกต้อง",
    "counterPartyNotFound": "ไม่พบคู่ค้า",
    "ofacFailed": "การตรวจสอบ OFAC ล้มเหลว",
    "interSORFail": "ไม่อนุญาตให้ทำธุรกรรมระหว่าง SOR",
    "invalidRoutingNum": "หมายเลขเส้นทางไม่ถูกต้อง",
    "requestedServiceNotAvail": "ในขณะนี้บริการที่ร้องขอไม่พร้อมใช้งาน",
    "limitExceedGlobal": "เกินขีดจำกัดทั่วโลก",
    "clientLimitExceed": "เกินขีดจำกัดธุรกรรมของลูกค้า",
    "dailyLimitExceed": "เกินขีดจำกัดธุรกรรมรายวัน",
    "invalidRecdetails": "รายละเอียดผู้รับไม่ถูกต้อง",
    "unabletoQuote": "ไม่สามารถขอใบเสนอราคาได้",
    "enterRecipntPhone": "ป้อนหมายเลขโทรศัพท์ผู้รับ",
    "enterAccNumber": "ป้อนหมายเลขบัญชีผู้รับ",
    "accountNotMatch": "หมายเลขบัญชีไม่ตรงกัน",
    "recipientAccount": "บัญชีผู้รับ",
    "convertedAmount": "จำนวนที่แปลงแล้ว",
    "paymentSuccess": "สร้างการชำระเงินเรียบร้อยแล้ว!",
    "orderNumber": "หมายเลขคำสั่งซื้อ",
    "refNumber": "หมายเลขอ้างอิง",
    "details": "รายละเอียด",
    "NoTransactionsFound": "ไม่พบธุรกรรม",
    "conntedTOQB": "เชื่อมต่อกับ QuickBooks สำเร็จ",
    "QuoteConvertedAmount": "จำนวนเงินที่แปลงให้เสร็จสิ้นทั้งหมด",
    "QBConnectFailed": "การเชื่อมต่อกับ QuickBooks ล้มเหลว",
    "conenctingToQB": "กำลังเชื่อมต่อกับ QuickBooks โปรดรอ...",
    "synchronizeFailed": "การซิงโครไนซ์ QuickBooks ล้มเหลว",
    "synchronizeSuccess": "ซิงโครไนซ์ QuickBooks เรียบร้อยแล้ว",
    "syncQBText": "ยังคงซิงค์กับ QuickBooks Online",
    "accountConnectedToQB": "บัญชีเชื่อมต่อกับ QuickBooks แล้ว",
    "profileChangesSaved": "การเปลี่ยนแปลงโปรไฟล์ของคุณได้รับการบันทึก",
    "Timezone": "เขตเวลา",
    "Locale": "สถานที่ตั้ง",
    "passwordUpdateSuccess": "การเปลี่ยนแปลงรหัสผ่านของคุณได้รับการบันทึกเรียบร้อยแล้ว",
    "passwordReqText": "รหัสผ่านต้องปฏิบัติตามข้อกำหนดที่ระบุ",
    "passwordNotMatch": "รหัสผ่านสองรหัสที่คุณป้อนไม่ตรงกัน",
    "newUserSuccess": "สร้างผู้ใช้ใหม่เรียบร้อยแล้ว",
    "addNewUser": "เพิ่มผู้ใช้ใหม่",
    "ssoReq": "ต้องการ SSO",
    "logoutTxt": "ออกจากระบบเรียบร้อยแล้ว",
    "updatePaymentSuccess": "อัปเดตเครื่องมือชำระเงินเรียบร้อยแล้ว",
    "updatePaymentFailed": "การอัปเดตเครื่องมือชำระเงินล้มเหลว",
    "noDataFoundFound": "ไม่พบข้อมูล",
    "completed": "เสร็จสมบูรณ์",
    "failed": "ล้มเหลว",
    "pending": "รอดำเนินการ",
    "sent": "ส่งแล้ว",
    "SessionExpired": "หมดอายุการใช้งานเซสชัน",
    "Uwallet": "Uwallet",
    "UWalletfunding": "การทุน UWallet",
    "debit": "เดบิต",
    "credit": "เครดิต",
    "active": "ใช้งาน",
    "ccintl": "บัตรเครดิตระหว่างประเทศ",
    "amex_dis": "Amex/Discover",
    "amex_card_text": "เพิ่มบัตร AMEX",
    "nodata": "ไม่มีข้อมูล",
    "note": "หมายเหตุ",
    "load_fund_via_card": "โหลดเงินผ่านบัตร",
    "load_fund_via_ach": "โหลดเงินผ่าน ACH",
    "load_fund_via_connector": "โหลดเงินผ่านตัวเชื่อมต่อ",
    "p2m_via_connector": "P2M ผ่านตัวเชื่อมต่อ",
    "creditAdjustment": "การปรับเครดิต",
    "debitAdjustment": "การปรับเดบิต",
    "endDateText": "วันที่สิ้นสุด",
    "payIn": "เพย์อิน",
    "payOut": "เพย์เอาท์",
    "checking": "ตรวจสอบ",
    "saving": "ออม",
    "verify": "ตรวจสอบ",
    "reset": "รีเซ็ต",
    "today": "วันนี้",
    "a2a_reversal": "การย้อนกลับ A2A",
    "commission_payout_via_ach": "การจ่ายค่าคอมมิชชั่นผ่าน ACH",
    "commission_payout_via_uWallet": "การจ่ายค่าคอมมิชชั่นผ่าน UWallet",
    "commission_payout_via_payquicker": "การจ่ายค่าคอมมิชชั่นผ่าน Payquicker",
    "withdraw_via_ach": "ถอนเงินผ่าน ACH",
    "withdraw_via_payquicker": "ถอนเงินผ่าน Payquicker",
    "credit_reversal": "การย้อนกลับเครดิต",
    "load_fund_via_card_reversal": "การย้อนกลับการโหลดเงินผ่านบัตร",
    "uwallet_funding_reversal": "การย้อนกลับการทุน U Wallet",
    "processing": "กำลังประมวลผล",
    "declined": "ถูกปฏิเสธ",
    "onHold": "อยู่ระหว่างการค้าง",
    "scheduled": "ตามกำหนด",
    "cancelled": "ถูกยกเลิก",
    "beneficiaryName": "ชื่อผู้รับผลประโยชน์",
    "b_b_code": "รหัสธนาคารและรหัสสาขา",
    "validBankCode": "โปรดป้อนรหัสที่ถูกต้อง",
    "minBankCode": "รหัสต้องมีอย่างน้อย 7 หลัก",
    "load_fund_wallet": "โหลดเงินในวอลเล็ต",
    "ach_transfer": "การโอนผ่าน ACH",
    "mater_visa": "มาสเตอร์การ์ด/วีซ่า",
    "go_to_transaction": "ไปที่ธุรกรรม",
    "p2m_reversal": "การย้อนกลับ P2M",
    "deposit_account_list": "รายการบัญชีฝาก",
    "load_fund_via_ach_reversal": "การย้อนกลับการโหลดเงินผ่าน ACH",
    "load_fund_via_connector_reversal": "การย้อนกลับการโหลดเงินผ่านตัวเชื่อมต่อ",
    "KYC_Failed": "การตรวจสอบ KYC ล้มเหลว",
    "updateKYCText": "คุณต้องการอัปเดตรายละเอียด KYC หรือไม่?",
    "userType": "ประเภทผู้ใช้",
    "usertypeErr": "โปรดเลือกประเภทผู้ใช้",
    "recidencyNumberTxt": "หมายเลขบัตรประจำตัว",
    "validResNum": "จำเป็นต้องระบุหมายเลขบัตรประจำตัว",
    "validresidencyNum": "โปรดป้อนหมายเลขบัตรประจำตัวที่ถูกต้อง",
    "flexText": "PayQuicker Flex",
    "flexTextVietnam": "Thanh toán linh hoạt",
    "withdrawalConfig": "การกำหนดค่าถอน",
    "withdrawalConfiguration": "การกำหนดค่าถอน",
    "addConfig": "เพิ่มการกำหนดค่า",
    "addFreequencyConfig": "เพิ่ม/แก้ไขการกำหนดค่าความถี่",
    "ReceivingAccount": "บัญชีรับเงิน",
    "deactivateConfig": "คุณต้องการที่จะปิดการใช้งานการกำหนดค่าที่เลือกหรือไม่?",
    "updateConfigSuccess": "อัปเดตการกำหนดค่าเรียบร้อยแล้ว",
    "updateConfigFailed": "อัปเดตการกำหนดค่าล้มเหลว",
    "add_card_text": "เพิ่มบัตรเครดิต",
    "UWalletTransfer": "การโอนเงิน UWallet",
    "decimalNotPermit": "ไม่อนุญาตให้ใช้ทศนิยม",
    "paybyCard": "จ่ายด้วยบัตร",
    "daily": "รายวัน",
    "weekly": "รายสัปดาห์",
    "monthly": "รายเดือน",
    "biweekly": "รายสัปดาห์คู่",
    "immediate": "ทันที",
    "percentage": "เปอร์เซ็นต์",
    "percentError": "โปรดป้อนเปอร์เซ็นต์ที่ถูกต้อง",
    "toDistributor": "ถึงผู้กระจายสินค้า",
    "distributorId": "โปรดป้อนรหัสผู้กระจายสินค้า",
    "invalidDistributor": "โปรดป้อนรหัสผู้กระจายสินค้าที่ถูกต้อง",
    "distributorNotFound": "ไม่พบผู้กระจายสินค้า",
    "distributorKYCError": "ผู้รับสินค้าไม่ได้รับการตรวจสอบ KYC",
    "validIDNum": "โปรดป้อนหมายเลขที่ถูกต้อง",
    "swiftCode": "รหัส SWIFT",
    "validBankName": "โปรดป้อนชื่อธนาคารที่ถูกต้อง",
    "idNumber": "หมายเลขประจำตัว",
    "identificationType": "ประเภทการระบุตัวตน",
    "identificationValue": "ค่าการระบุตัวตน",
    "valididentificationValue": "โปรดป้อนค่าการระบุตัวตนที่ถูกต้อง",
    "valididentificationType": "โปรดเลือกประเภทการระบุตัวตน",
    "CITCON WITHDRAWAL": "ถอนเงินผ่านธนาคาร",
    "validCityName": "โปรดป้อนชื่อเมืองที่ถูกต้อง",
    "pendingApproval": "รอการอนุมัติ",
    "purposeCode": "วัตถุประสงค์ของการชำระเงิน",
    "plsSeletpurposeCode": "โปรดเลือกวัตถุประสงค์ของการชำระเงิน",
    "QualifyingCredit": "เครดิตที่มีสิทธิ์",
    "tooltipAccountName": "โปรดป้อนชื่อเต็มของคุณรวมถึงชื่อและนามสกุล (หากเป็นบุคคล) เช่น John Smith หรือชื่อบริษัทที่ถูกต้องเต็มรูปแบบ (หากเป็นบริษัท) เช่น บริษัท อบอุ่น จำกัด",
    "tooltipAccountNumber": "ป้อนหมายเลขบัญชีธนาคาร",
    "tooltipBankCode": "ป้อนรหัสธนาคาร",
    "tooltipBranchCode": "ป้อนรหัสสาขา",
    "tooltipSwiftBic": "โปรดป้อน SWIFT BIC ของธนาคารของคุณซึ่งสามารถมีความยาวได้ 8 หรือ 11 ตัวอักษร หาก SWIFT BIC มีแค่ 8 ตัวอักษร กรุณาอย่าเติมความยาวให้เป็น 11 ตัวอักษร โดยการเพิ่ม XXX",
    "tooltipAddress": "โปรดป้อนหมายเลขที่อยู่ของคุณ (หากเป็นบุคคล) หรือที่อยู่จดทะเบียน (หากเป็นบริษัท) ชื่อและที่อยู่ถนน เช่น 1 ถนนเมนสตรีท",
    "tooltipCity": "โปรดป้อนชื่อเมืองหรือเทาน์ของที่อยู่อาศัยของคุณ (หากเป็นบุคคล) หรือที่อยู่จดทะเบียน (หากเป็นบริษัท) เช่น ลอนดอน",
    "tooltipZipcode": "โปรดป้อนรหัสไปรษณีย์หรือไปรษณีย์ของที่อยู่อาศัยของคุณ (หากเป็นบุคคล) หรือที่อยู่จดทะเบียน (หากเป็นบริษัท)",
    "tooltipCountry": "โปรดเลือกประเทศของที่อยู่อาศัยของคุณ (หากเป็นบุคคล) หรือที่อยู่จดทะเบียน (หากเป็นบริษัท)",
    "tooltipDOB": "โปรดเลือกวันเกิดของคุณ (หากเป็นบุคคลเท่านั้น)",
    "tooltipBankName": "โปรดป้อนชื่อธนาคารของคุณ",
    "bankCode": "รหัสธนาคาร",
    "branchCode": "รหัสสาขา",
    "dob": "วันเกิด",
    "payee": "ผู้รับเงิน",
    "IBAN": "IBAN",
    "tooltipIBAN": "GBxx xxxx xxxx xxxx xxxx xx",
    "payeeTooltip": "ชื่อเต็มหรือชื่อบริษัททางกฎหมายของคุณ",
    "pc_from_text": "จาก",
    "pc_to_text": "ถึง",
    "CNY PAYOUT": "ถอนเงินผ่านธนาคาร",
    "accountHolderaddress": "ที่อยู่ของเจ้าของบัญชี",
    "accountHolderName": "ชื่อเจ้าของบัญชี",
    "branchName": "ชื่อสาขา",
    "continue": "ดำเนินการต่อ",
    "validBranchName": "โปรดป้อนชื่อสาขาที่ถูกต้อง",
    "SOLU": "ถอนเงินผ่านธนาคาร",
    "cardUpdatedSuccessfully": "อัปเดตบัตรเรียบร้อยแล้ว",
    "reason": "เหตุผล",
    "failedUpdateCard": "อัปเดตบัตรไม่สำเร็จ",
    "kycNotVerified": "ยังไม่ได้ตรวจสอบ KYC",
    "VND PAYOUT": "ถอนเงินผ่านธนาคาร",
    "phoneNumberAliWP": "86 XXX XXXX XXXX",
    "phoneTooltip": "กรุณาระบุหมายเลขโทรศัพท์ของผู้รับ (ตัวอย่าง: 86 XXX XXXX XXXX)",
    "emailTooltip": "กรุณาใส่อีเมลของผู้รับ",
    "ALIPAY PAYOUT WP": "Alipay",

    "enterValidAccNameWithLimit": "Enter Valid Payee name with minimum 2 and maximum 70 characters",
    "enterValidAccNumWithLimit": "Enter Valid account number with minimum 4 and maximum 17 characters",
    "enterValidRoutingNumWithLimit": "Enter Valid routing number",
    "enterValidAddressWithLimit": "Enter Valid Address with minimum 2 and maximum 70 characters",
    "enterValidCityWithLimit": "Enter Valid City with minimum 2 and maximum 35 characters",
    "enterValidZipcodeWithLimit": "Enter Valid Zip code with minimum 2 and maximum 10 characters",

    "enterValidAccNameWithChinese": "Enter valid name in Chinese Characters",
    "enterValidBankNameWithChinese": "Enter valid Bank name in Chinese Characters",
    "enterValidBranchNameWithChinese": "Enter valid Branch name in Chinese Characters",
    "enterValidSoluAccNum": "Enter Valid numeric account number",
    "enterValidSoluAccNumWith20Limit": "Enter Valid numeric account number maximum 20 characters.",
    "enterValidSoluAccNumWith17Limit": "Enter Valid numeric account number maximum 17 characters.",
    "enterValidSoluPhoneNumWithLimit": "Enter Valid numeric phone number maximum 20 characters.",
    "enterValidSoluIDNumWithLimit": "Enter Valid alphanumeric ID number maximum 100 characters.",

    "enterValidAlipayPayeeNameWithLimit": "Enter Valid Payee name with minimum 2 and maximum 35 characters",
    "enterValidAlipayPhoneNumWithLimit": "Enter Valid numeric phone number with minimum = 5 maximum 34 characters.",
    "enterValidAlipayEmailWithLimit": "Enter Valid Email address  with minimum = 6 maximum 100 characters.",

    "enterValidThanhAccNameWithChinese": "Enter Valid  account name with minimum 1 and maximum 140 characters.",
    "enterValidThanhAccNumWithLimit": "Enter Valid  account number  with minimum 1 and maximum 30 characters.",
    "enterValidThanhSwiftCodeWithLimit": "Enter Valid swift code with minimum 8 and maximum 11 characters.",
    "enterValidThanhAddressWithLimit": "Enter Valid  address  with minimum 2 and maximum 35 characters.",

    "enterValidBeneficiaryNameWithLimit": "Enter valid name with maximum 20 characters.",
    "enterValidTWDAccNumWithLimit": "Enter valid account number with maximum 20 characters.",
    "enterValidTWDBranchCodeWithLimit": "Enter valid branch code with maximum 7 characters.",

    "enterValidMYRAccNameWithLimit": "Enter valid name with minimum = 2 and maximum 50 characters.",
    "enterValidMYRAccNumWithLimit": "Enter valid numeric account number with maximum 16 characters.",

    "enterValidTHBAccNameWithLimit": "Enter valid name with maximum 35 characters.",
    "enterValidTHBAccNumWithLimit": "Enter valid numeric account number  with  maximum 12 characters.",
    "enterValidTHBSwiftCodeWithLimit": "Enter valid swift code with maximum 11 characters.",
    "enterValidTHBAddressWithLimit": "Enter valid Address with minimum = 5 and maximum 35 characters.",
    "enterValidTHBCityWithLimit": "Enter valid city with maximum 60 characters.",
    "enterValidTHBZipcodeWithLimit": "Enter valid zip code with minimum = 5 and maximum 10 characters.",

    "enterValidGBPPayeeNameWithLimit": "Enter valid name with Minimum 3 and maximum 50 characters.",
    "enterValidGBPIbanWithLimit": "Enter valid Iban with maximum 22 characters.",
    "enterValidGBPBankNameWithLimit": "Enter valid Bank name with Minimum = 1 and maximum 50 characters.",

    "enterValidEURAccNameWithLimit": "Enter valid name with Minimum 1 and maximum 50 characters.",
    "enterValidEURAccNumWithLimit": "Enter valid numeric account number  with  Minimum 1 and maximum 35 characters.",
    "enterValidEURBankNameWithLimit": "Enter valid Bank name with Minimum = 2 and maximum 10 characters.",

    "enterValidJPYBankNameWithLimit": "Enter valid bank name with  maximum 35 characters.",
    "acceptTermsandconditions": "I followed the guide and set up my Alipay account to receive funds",
    "failedDuplicateCard": "Card addition failed as card already exist.",
    "updatedDate": "Updated Date"
}