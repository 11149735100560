import './auth.scss';
import React, { useState } from 'react'
import { Layout, message } from 'antd'
import { Suspense } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import { useContext } from 'react';
import DomainContext from '../app-utils/DomainContext.jsx';
import Api from '../service/api';

const api = new Api();
const Login = React.lazy(() => import('./login'));
const Verify = React.lazy(() => import('./OTP'));
const Reset = React.lazy(() => import('./Reset'));
const Forgot = React.lazy(() => import('./forgot-password'));


const Auth = (props) => {
    
    const history = useHistory();
    const domainDetails = useContext(DomainContext)
    const [ tokenVerified , settokenVerified ] = useState();

    useEffect(() => {
        let { access_token } = props.match?.params || {};

        if (props.location && props.location.pathname === '/') {
            history.push("/login");
        }
        if (props.match?.params?.token && props.match.path.indexOf('/login') >= 0) {
            settokenVerified(false)
            verifyToken();
            return;
        }
        if(props.match?.params?.guid && props.match.path.indexOf('/auth/access') >= 0){
            settokenVerified(false);
            validateGuid();
        }
        const lang = getLangFromHash();
        if (lang) {
            sessionStorage.setItem('lang', lang);
        }

        if (access_token && access_token.includes('&')) {
            access_token = access_token.split('&')[0];
        }

        if(access_token && props.match.path.indexOf('/ssologin') >= 0){
            sessionStorage.setItem('access_token', access_token)
            sessionStorage.setItem('refresh_token', access_token)
            history.push("/dashboard");
        }

        settokenVerified(true);
         // eslint-disable-next-line
    },[]);

    const getLangFromHash = () => {
        const hash = window.location.hash;
        const langMatch = hash.match(/[?&]lang=([^&]+)/);
        return langMatch ? langMatch[1] : null;
    };


    const verifyToken = async () => {
        var url = 'auth/verify/first-login';
        let result = await api.mainRestCallService(url, 'POST', { token: props.match.params.token });
        if (result?.status === 1) {
            settokenVerified(true)
        } else {
            // this.setState({tokenVerified: true})
            message.error('Verification token expired')
        }
    }

    const getPathName = (name) => {
        if(props.location.pathname.indexOf(name) >= 0){
            return true;
        }
        return false;
    }

    const validateGuid = async () => {
        const guid = props.match?.params?.guid;
        const url = 'auth/access/nul?guid=' + guid;
        let result = await api.mainRestCallService(url, 'GET');
        if (result?.status === 1) {
            settokenVerified(true)
            sessionStorage.setItem('access_token', result.data.access_token)
            sessionStorage.setItem('refresh_token', result.data.refresh_token)
            history.push("/dashboard");
        } else {
            message.error('User not found.')
        }
    }

    return (
        <Layout className="authLayout">
            <div className="authBox">
                <div className="victorLogo" style={{ textAlign: 'center' , backgroundImage: `url(${domainDetails?.cdnDomain + domainDetails?.logo})`}}></div>
                <Suspense fallback={<div>Loading...</div>}>
                    {
                        tokenVerified && 
                        <>
                            {(getPathName('/login')) ? <Login signUpUrl={domainDetails.signupLink}/> : null}
                            {(getPathName('/verify-otp')) ? <Verify /> : null}
                            {(getPathName('/forgot-password')) ? <Forgot /> : null }
                            {(getPathName('/reset-password') || getPathName('/setpassword')) ? <Reset /> : null}
                        </>
                    }
                </Suspense>
            </div>
        </Layout>
    )
}

export default withRouter(Auth);